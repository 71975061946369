<template>
  <div class="popup" v-show="boxShow">
    <div class="detail bg_white">
      <div class="header">
        <div class="fl">
          <span class="fl f14 bold400 txt_black85">用户：</span>
          <input type="text" class="txt_black54 f14 bold400" placeholder="请输入用户名" v-model="searchUser" @change="userChangeEvent" />
        </div>
      </div>
      <lists class="lists" ref="lists" :data="lists" :listsStyle="listsStyle" :httpRequestData="httpRequestData"></lists>
      <ul class="check">
        <li>
          <Checkbox :indeterminate="indeterminate" v-model="allChecked" @on-change="checkAllEvent">全选</Checkbox>
        </li>
        <li v-for="(v,key) in lists.data" :key="key">
          <Checkbox v-model="v.check" @on-change="setAllCheckStatus"></Checkbox>
        </li>
      </ul>
      <div class="buttons">
        <div class="fl f14 txt_4673E3 bold400 cursor_pointer" @click="boxShow=false">取消</div>
        <button class="fr f14 txt_white bold400 tc cursor_pointer bg_E91E63" @click="confirmEvent">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
  import Lists from '@/components/Lists'
  export default {
    name: "selectUser",
    components: {
      Lists
    },
    data() {
      return {
        boxShow: false,
        allChecked: false,
        indeterminate: false,
        searchUser: '',
        lists: {
          title: [{text: '用戶名'},{text:'openid'},{text:'省市'}],
          data: []
        },
        listsStyle: [
          {width: '280px',height: '45px',lineHeight: '45px'},
          {width: '280px',height: '45px',lineHeight: '45px'},
          {width: '100px',height: '45px',lineHeight: '45px'},
        ],
        httpRequestData: {
          url: this.url || '/manage/user_data',
          func: this.httpRequestHandle,
          getData: this.listGetData,
          error: '获取用户信息出错',
          auto: false
        },
      }
    },
    props: {
      url: String,
    },
    methods: {
      init() {
        this.boxShow = true;
      },
      userChangeEvent() {
        if(this.searchUser){
          this.$refs.lists.updateData({user_name: this.searchUser});
        }
      },
      checkAllEvent() {
        if(this.indeterminate){
          this.indeterminate = false;
          this.allChecked = false;
        }
        this.lists.data.forEach((item)=>{
          item.check = this.allChecked;
        })
      },
      setAllCheckStatus() {
        let counter = 0;
        this.lists.data.forEach((item)=>{
          if(item.check){
            counter ++;
          }
        });
        if(counter === 0){
          this.allChecked = false;
          this.indeterminate = false;
        }else if(counter >= 10){
          this.allChecked = true;
          this.indeterminate = false;
        }else{
          this.allChecked = true;
          this.indeterminate = true;
        }
      },
      closeEvent() {
        this.boxShow = false;
      },
      confirmEvent() {
        let data = [];
        this.lists.datas.forEach(item=>{
          item.forEach(item1=>{
            if(item1.check) {
              data.push(item1);
            }
          })
        });
        this.$emit('confirmCallback',data);
        this.closeEvent();
      },
      listGetData(data) {
        data = data.data;
        if(!data.map) {
          data = data.data;
        }
        return data;
      },
      httpRequestHandle(data) {
        return data.map(item =>{
          let address = item.province ? item.province + '·' + item.city : '暂无数据';
          item.check = false;
          return [
            {text: item.nickname},
            {text: item.openid},
            {text: address}
          ];
        });
      }
    }
  }
</script>

<style scoped>
  .detail {
    width: 900px;
  }
  .header {
    height: 70px;
    padding: 17px 27px;
    line-height: 30px;
  }
  .header input {
    width: 200px;
    height: 36px;
    line-height: 36px;
    border: 1px solid rgba(204, 204, 204, 1);
    border-radius: 3px;
    padding: 0 10px;
  }
  .popup>>>.lists{
    height: 565px !important;
  }
  .popup>>>.listContent {
    height: 460px !important;
  }
  .popup>>>.page {
    height: 60px;
    line-height: 60px;
  }

  .check{
    position: absolute;
    top: 70px;
    right: 25px;
  }
  .check>li{
    width: 100px;
    text-align: center;
    height: 46px;
    line-height: 46px;
  }
  .check>li:nth-of-type(1){
    height: 45px;
    line-height: 45px;
  }
</style>
