function ListObj(array1,array2){
  this.array1 = array1;
  this.array2 = array2;
}
ListObj.prototype.get = function(arg) {
  let index = this.array2.indexOf(arg);
  if(index !== -1){
    return this.array1[index];
  }else{
    index = this.array1.indexOf(parseInt(arg));
    return this.array2[index];
  }
};
ListObj.prototype.getBin = function(arg) {
  let result = '';
  if(arg[0] == '0' || arg[0] == '1') {
    let array = arg.split('');
    array.forEach((item,index) => {
      if(item == '1'){
        result += !result ? this.array2[index] : '·' + this.array2[index];
      }else {
        result += !result ? this.array1[index] : '·' + this.array1[index];
      }
    })
  }else {
    let array = arg.split('·');
    array.forEach((item) => {
      if(this.array1.includes(item)){
        result += '0';
      }else {
        result += '1';
      }
    })
  }
  return result;
};
ListObj.prototype.getItem = function(index){
  if(index === 0){
    return this.array1;
  }else if(index === 1){
    return this.array2;
  }else if(index === 2){
    return this.array1.map((item,index) => {
      return {
        id: item,
        text: this.array2[index]
      }
    })
  }
};

const config = {
  roleType: new ListObj([1,2,3,5],['admin','common','live','store']),
  storeType: new ListObj([0,1],['下架','上架']),
  roomType: new ListObj([1,2],['预约消费','即时消费']),
  roomStatus: new ListObj(['空闲','离线','下架'],['使用中','在线','上架']),
};

export default config
