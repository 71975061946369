<template>
    <div class="storeBox w100 h100">
        <store-info ref="storeInfo" @callback="callback"></store-info>
        <room-info ref="roomInfo" @callback="callback"></room-info>
    </div>
</template>

<script>
    import StoreInfo from './storeInfo'
    import RoomInfo from './roomInfo'

    export default {
        name: "store",
        components: {
            StoreInfo, RoomInfo
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.$refs.storeInfo.init();
                this.$refs.roomInfo.boxShow = false;
            },
            callback(node, ...args) {
                this.$refs[node].init(...args);
            }
        }
    }
</script>

<style scoped>

</style>
