import axios from 'axios'
import router from '../router'
/*
 * 定义请求类型
 */
const METHOD_GET = 'GET';
const METHOD_POST = 'POST';
const METHOD_PUT = "PUT";
const METHOD_DELETE = "DELETE";

/**
 * @param method 请求类型
 * @param api 资源的路径
 * @param param 上传的数据
 **/
let paramParse = function (method, api, param) {
    // let url = '/api' + api;
    let url = 'https://bike.appaudio.cn' + api;
    return new Promise((resolve, reject) => {
        let data = {
            url: url,
            method: method
        };
        if (method === METHOD_GET) {
            data.params = param;
        }else {
            data.data = param;
        }
        axios(data).then((response) => {
          if (response.data.status === 200 || response.status === 201) {
            resolve(response.data.data)
          }else if(response.data.status === 203){
            if(router.history.current.name !== 'login'){
              router.push({path: '/login'})
            }
          }else {
            reject(response.data.msg)
          }
        }).catch((error) => {
            reject(error.message)
        })
    })
};

const Api = {
    /**
     * 查询操作
     */
    get (api, param) {
        return paramParse(METHOD_GET, api, param)
    },
    /**
     * 新增操作
     */
    post (api, param) {
        return paramParse(METHOD_POST, api, param)
    },
    /**
     * 修改操作
     */
    put (api, param) {
        return paramParse(METHOD_PUT, api, param)
    },
    /**
     * 删除操作
     */
    delete (api, param) {
        return paramParse(METHOD_DELETE, api, param)
    },
};

export default Api
