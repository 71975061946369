import Vue from 'vue'
import vuex from 'vuex'
import user from './modules/user'
import prompt from './modules/prompt'
import provinces from './modules/provinces'
Vue.use(vuex);

export default new vuex.Store({
  modules:{
    user,
    prompt,
    provinces
  },
});
