/*用户数据*/

const state={
  login: false,
  phone: '',
  password: '',
  role: '',
};
const getters={
  login: state => state.login,
  phone: state => state.phone,
  password: state => state.password,
  role: state => state.role,
};
const actions={
};
const mutations={
  setLogin(state,data){
    if(data.status){
      state.login = data.status;
      state.phone = data.phone;
      state.password = data.password;
      state.role = data.role;
      window.localStorage.setItem('phone',data.phone);
      window.localStorage.setItem('password',data.password);
      window.localStorage.setItem('loginTime',data.loginTime);
      window.localStorage.setItem('role',data.role);
    }else{
      state.login = false;
      state.phone = '';
      state.password = '';
      state.role = '';
      window.localStorage.clear();
    }
  }
};

export default {
  namespaced:true,
  state,
  getters,
  mutations,
  actions
}
