<template>
  <div class="popup" v-show="boxShow">
    <div class="detail bg_white">
      <div class="title f18 bold500 txt_black87 tc">
        <span v-if="flag==='create'">新建体验店</span>
        <span v-if="flag==='edit'">查看体验店</span>
        <span v-if="flag==='view'">编辑体验店</span>
      </div>


      <div class="content overflow_hidden">
        <div class="mask" v-show="flag==='view'"></div>
        <div class="fl left">
          <div class="item f14 bold500">
            <div class="title bold600 content-center"><span class="iconfont icon-bixuan f20"></span>体验店名称：</div>
            <div class="ipt">
              <input class="w100 h100" type="text" placeholder="请输入体验店名称" v-model="data.store_name"/>
            </div>
          </div>

          <div class="item f14 bold500">
            <div class="title bold600 content-center"><span class="iconfont icon-bixuan f20"></span>省市区：</div>
            <div>
              <Cascader class="cascader" :data="provinces" :value="[this.data.province,this.data.city,this.data.district]"  @on-change="cascaderChange"></Cascader>
            </div>
          </div>

          <div class="item f14 bold500">
            <div class="title bold600 content-center"><span class="iconfont icon-bixuan f20"></span>体验店详细地址：</div>
            <div class="ipt">
              <input class="w100 h100" type="text" placeholder="请输入详细地址" v-model="data.store_address" @change="setGeoLocation"/>
            </div>
          </div>

          <div class="item f14 bold500">
            <div class="title bold600 content-center"><span class="iconfont icon-bixuan f20"></span>营业时间：(务必对应包间价格)</div>
            <div class="ipt">
              <TimePicker format="HH:mm" :steps="[1, 60]" class="w100 h100" type="timerange" placement="bottom-end"
                          placeholder="请选择营业时间"  v-model="business_hours"></TimePicker>
            </div>
          </div>

          <div class="item f14 bold500">
            <div class="title bold600 content-center"><span class="iconfont icon-bixuan f20"></span>合伙人：</div>
            <div class="ipt">
              <selection :options="partners" @change="partnerChange"></selection>
            </div>
          </div>

          <div class="item f14 bold500">
            <div class="title bold600 content-center"><span class="iconfont icon-bixuan f20"></span>抽成比例（单位%）：</div>
            <div class="ipt">
              <input class="w100 h100" type="number" placeholder="请输入抽成比例" min="0" max="100" v-model="data.scale"/>
            </div>
          </div>

          <div class="item f14 bold500">
            <div class="title bold600 content-center"><span class="iconfont icon-bixuan f20"></span>服务电话：</div>
            <div class="ipt">
              <input class="w100 h100" type="text" placeholder="请输入服务电话" v-model="data.service_phone"/>
            </div>
          </div>
        </div>

        <div class="fr right">
          <div class="item txt_black54 f14 bold500 coordinate">
            <div class="title bold600 content-center"><span class="iconfont icon-bixuan f20"></span>体验店位置(可拖动地图上位置)：</div>
            <div class="ipt">
              <span class="fl">x:</span><input type="text" placeholder="请输入体验店地址" v-model="data.coordinate[0]" @change="setMarkerMapPosition"/>
              <span class="fl">y:</span><input type="text" placeholder="请输入体验店地址" v-model="data.coordinate[1]" @change="setMarkerMapPosition"/>
            </div>
          </div>

          <div class="map" id="mapContainer"></div>

          <div class="store_img item txt_black54 f14 bold500">
            <div class="title bold600 content-center"><span class="iconfont icon-bixuan f20"></span>体验店图片：</div>
            <div class="image" v-show="!storeImg">
              <div class="uploader cursor_pointer">
                <input class="cursor_pointer f0" accept="image/jpeg,image/png" type="file" @change="getImage" />
<!--                <Icon class="txt_3399ff" type="ios-cloud-upload" size="50" />-->
                <p class="f14">请上传图片（2M以内,长宽比尽量为2:1）</p>
              </div>
            </div>
            <div class="image" v-show="storeImg">
              <img :src="storeImg" alt="" />
<!--              <Icon class="closer cursor_pointer" type="md-close-circle" size="20" @click="storeImg=''" />-->
            </div>
          </div>
        </div>
      </div>

      <div class="buttons">
        <div class="fl f14 txt_4673E3 bold400 cursor_pointer" @click="boxShow=false">取消</div>
        <button class="fr f14 txt_white bold400 tc cursor_pointer bg_E91E63" @click="confirmEvent">确认</button>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: "storeDetail.vue",
    data() {
      return {
        boxShow: false,
        flag: 'create',
        map: null,
        geo: null,
        marker: null,
        partners:[],
        business_hours: '',
        storeImg: '',
        data: {
          store_id: '',
          store_name: '',
          province: '',
          city: '',
          district: '',
          store_address: '',
          partner_id: '',
          scale: '',
          service_phone: '',
          coordinate: [],
        },
      }
    },
    computed: {
      ...mapGetters({
        provinces: 'provinces/provinces',
        role: 'user/role'
      }),
    },
    created() {
      this.loadMap();
      this.getPartners();
    },
    methods: {
      loadMap() {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://map.qq.com/api/js?v=2.exp&key=IR4BZ-CJG6W-FRDR2-RMIB6-4N5OT-4RFOS&callback=init";
        document.body.appendChild(script);
        window.init = () => {
          var myLatlng = new window.qq.maps.LatLng(30.541502016229398,104.07596100781248);
          var myOptions = {
            zoom: 8,
            center: myLatlng,
            mapTypeId: window.qq.maps.MapTypeId.ROADMAP
          };
          this.map = new window.qq.maps.Map(document.getElementById("mapContainer"), myOptions);

          this.geo = new window.qq.maps.Geocoder();

          this.marker = new window.qq.maps.Marker({
            position: myLatlng,
            draggable: true,
            map: this.map
          });
          window.qq.maps.event.addListener(this.marker, 'dragend', (event) => {
            this.data.coordinate = [event.latLng.getLat(),event.latLng.getLng()];
            this.setMarkerMapPosition();
          });
          window.qq.maps.event.addListener(this.map, 'dragend', () => {
            let pos = this.map.getCenter();
            this.data.coordinate = [pos.lat,pos.lng];
            this.setMarkerMapPosition();
          });
        }
      },
      setMarkerMapPosition(){
        if(!this.marker || !this.map) return;
        var myLatlng = new window.qq.maps.LatLng(this.data.coordinate[0],this.data.coordinate[1]);
        this.marker.setPosition(myLatlng);
        this.map.setCenter(myLatlng);
      },
      setGeoLocation() {
        if(this.data.province && this.data.city && this.data.district && this.data.store_address) {
          this.geo.getLocation(this.data.province + this.data.city + this.data.district + this.data.store_address);//地址
          this.geo.setComplete(res => {
            let location = res.detail.location;
            this.data.coordinate = [location.lat,location.lng];
            this.setMarkerMapPosition();
          })
        }
      },
      init(flag,data) {
        this.flag = flag;
        if(this.flag === 'create'){
          for(let v in this.data){
            this.data[v] = '';
          }
          this.data.coordinate = [];
          this.storeImg = '';
          this.business_hours = '';
        }else{
          for(let v in this.data){
            if(data[v] !== undefined && data[v] !== null){
              this.data[v] = data[v];
            }
          }
          this.business_hours = data.business_hours.split('-');
          this.storeImg = 'http://hi.manage.appaudio.cn' + data.img;
          if(data.coordinate)this.setMarkerMapPosition();
        }
        this.boxShow = true;
      },
      getPartners() {
        if(this.partners.length > 0) return;
        this.$api.get('/manage/partner').then((data) => {
          this.partners = data.data.map(item => {
            return {
              text: item.name,
              id: item.id
            }
          });
        }).catch((error) => {
          this.$alert.error({content: '获取合伙人失败:' + error});
        })
      },
      partnerChange(data) {
        this.data.partner_id = data.id;
      },
      cascaderChange(data) {
        this.data.province = data[0];
        this.data.city = data[1];
        this.data.district = data[2];
        this.setGeoLocation();
      },
      getImage (event){
        let file = event.target.files[0];
        var reader=new FileReader();
        reader.readAsDataURL(file);
        reader.onload = ()=>{
          this.storeImg = reader.result;
          event.target.value = '';
        }
      },
      confirmEvent() {
        if(this.flag == 'view') {
          this.boxShow = false;
          return;
        }
        // for(let v in this.data){
        //   if(this.data[v] === undefined || this.data[v] === null || this.data[v] === ''){
        //     this.$modal({content: '请填写完整数据'});
        //     return;
        //   }
        // }
        if(this.data.scale < 0 || this.data.scale > 100){
          this.$modal({content: '抽成比例不可小于0或者大于100'});
          return;
        }
        if(this.data.coordinate[0] === undefined || this.data.coordinate[1] === undefined){
          this.$modal({content: '请选取坐标'});
          return;
        }
        if(!this.business_hours){
          this.$modal({content: '请选择营业时间'});
          return;
        }
        if(!this.storeImg){
          this.$modal({content: '请上传图片'});
          return;
        }
        this.data.country = '中国';
        this.data.business_hours = businessHoursFormatArray2Str(this.business_hours);
        if(isBase64(this.storeImg)){
          this.data.img = this.storeImg;
        }else{
          delete this.data.img;
        }
        if(this.flag === 'create'){
          this.$api.post('/manage/store',this.data).then(() => {
            this.$emit('successCallback');
            this.$alert.success({content: '新建体验店成功'});
            this.boxShow = false;
          }).catch((error) => {
            this.$alert.error({content: '新建体验店失败:' + error});
          })
        }else{
          this.$api.put('/manage/store/'+this.data.store_id,this.data).then(() => {
            this.$emit('successCallback');
            this.$alert.success({content: '修改体验店成功'});
            this.boxShow = false;
          }).catch((error) => {
            this.$alert.error({content: '修改体验店失败:' + error});
          })
        }

        function businessHoursFormatArray2Str(businessHours){
          businessHours = businessHours.map(item => {
            return item.replace(/^0+/,"");
          }).join('-');
          return businessHours;
        }

        function isBase64(str){
          return str.indexOf('data:') !== -1 && str.indexOf('base64') !== -1;
        }

      }
    }
  }
</script>

<style scoped>
  .detail{
    width:820px;
  }

  .content>.left{
    width: 314px;
  }
  .content>.right{
    width: 448px;
  }

  .coordinate>div>input{
    width: 196px;
    float: left;
    height: 36px;
    margin: 0 10px 0 5px;
  }

  .map{
    width: 448px;
    height: 220px;
    margin: 20px 25px 0 0;
  }

  .store_img{
    width: 448px;
    margin-right: 24px;
  }
  .store_img>.image{
    width: 100%;
    height: 195px !important;
    text-align: center;
    border: 1px solid rgba(204,204,204,1);
    position: relative;
    margin-top: 8px;
  }
  .store_img>.image>.uploader{
    width: 300px;
    height: 100px;
    border: 1px rgba(204,204,204,1) dashed;
    margin: 50.5px 74px;
    position: relative;
    padding-top: 10px;
  }
  .store_img>.image>.uploader>input{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    line-height: 0;
  }
  .store_img>.image>.closer{
    position: absolute;
    top: 0;
    right: 0;
  }
  .store_img>.image>img{
    max-width: 100%;
    max-height: 100%;
  }
</style>
