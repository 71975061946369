<template>
  <div class="listBox">
    <ul class="listTitle" v-if="data.title">
      <li v-for="(v,key) in data.title" :key="key" v-text="v.text" :style="getLiStyle(key,v)"></li>
    </ul>
    <div class="listContent" v-if="data.content&&data.content.length>0">
      <template v-for="(content,contentKey) in data.content">
        <ul class="listItem" :key="contentKey">
          <li v-for="(v,key) in content" :key="key" :style="getLiStyle(key,v)">
            <img v-if="v.img" :src="v.img.img_src" :style="v.img.style" alt="" />
            <span v-if="!v.arr" v-text="v.text"></span>
            <span v-if="v.arr">
            <template v-for="(e,eKey) in v.arr">
              <span v-if="v.arr" :key="eKey" :style="e.style"  :class="e.event&&'cursor_pointer'" @click="e.event&&listItemEvent(e.event,content,contentKey)">
                <span v-text="e.text"></span> <span v-if="eKey<v.arr.length-1" class="row">丨</span>
              </span>
            </template>
          </span>
          </li>
        </ul>
      </template>

    </div>

    <div class="tooltip" v-if="!data.content||data.content.length<=0">
      <div class="tc f18 txt_black85" v-show="load===1||load===undefined">---- 暂无数据 ----</div>
<!--      <div v-show="load===0">-->
<!--        <Spin fix>-->
<!--          <Icon type="ios-loading" size="50" class="demo-spin-icon-load"></Icon>-->
<!--        </Spin>-->
<!--      </div>-->
    </div>
    <div class="page">
      <span class="closer iconfont icon-zuojiantou f20 cursor_pointer" @click="pageEvent(parseInt(pageData.index) - 1)"></span>
      <input type="text" ref="pageIpt" v-model="pageData.index" @change="pageEvent(pageData.index)" />
      <span>/</span>
      <span v-text="pageData.total"></span>
      <span class="closer iconfont icon-youjiantou1 f20 cursor_pointer" @click="pageEvent(parseInt(pageData.index) + 1)"></span>
    </div>
  </div>
</template>
<script>
  export default {
    name: "Lists",
    data () {
      return {
        load: 1,
        pageData: {index: 1, total: 1},
        requestData: {page: 1, perpage: 10}
      }
    },
    created() {
      if(this.httpRequestData && this.httpRequestData.auto){
        this.updateData();
      }
    },
    methods:{
      getLiStyle(key,v){
        if(v && v.style){
          for (let item in this.listsStyle[key]) {
            if(! Object.prototype.hasOwnProperty.call(v.style, item)){
              v.style[item] = this.listsStyle[key][item];
            }
          }
          return v.style;
        }else{
          return this.listsStyle[key];
        }
      },
      pageEvent(index) {
        if(index >= 1 && index <= this.pageData.total){
          this.$set(this.pageData,'index',index);
          this.httpRequest(index);
        }
      },
      listItemEvent(event,content,index) {
        this.$emit(event,this.data.data[index],content,index);
      },
      updateData(data) {
        this.data.content = [];
        this.data.contents = [];
        this.data.data = [];
        this.data.datas = [];
        this.httpRequest(1,data);
      },
      httpRequest(page,obj){
        page = page || 1;
        if(this.data.contents && this.data.contents[page-1]){
          this.data.content = this.data.contents[page-1];
          this.data.data = this.data.datas[page-1];
          return;
        }else{
          this.data.content = [];
          this.data.data = [];
        }
        this.load = 0;
        if(!obj) {
          this.requestData.page = page;
        }else {
          this.requestData = {page: page, perpage: 10};
          for(let v in obj) {
            if(obj[v] !== '' && obj[v] !== null && obj[v] !== undefined){
              this.requestData[v] = obj[v];
            }
          }
        }
        this.$api.get(this.httpRequestData.url,this.requestData).then((data) => {
          if(!data) return;
          this.pageData = {
            index: data.current,
            total: data.total
          };
          if(!this.httpRequestData.getData){
            data = data.data;
          }else{
            data = this.httpRequestData.getData(data);
          }
          if(!data) return;
          if(this.httpRequestData.func){
            let content = this.httpRequestData.func(data);
            this.data.content = content;
            this.data.contents[page-1] = content;
            this.data.data = data;
            this.data.datas[page-1] = data;
          }
          this.load = 1;
        }).catch((error) => {
          this.load = 1;
          let message = '请检查网络，' + this.httpRequestData.error + ':' + error;
          this.$alert.error({content: message});
        })
      },
    },
    props:{
      data: Object,
      listsStyle: Array,
      httpRequestData: Object,
    }
  }
</script>

<style scoped>
  .demo-spin-icon-load{
    animation: ani-demo-spin 1s linear infinite;
  }
  .listBox{
    width: 100%;
    height: 740px;
    position: relative;
  }
  .listBox>.listTitle{
    padding: 0 30px;
    background-color: #FAFAFA;
    font-size: 14px;
    font-weight: 600;
    color: #4B4B4B;
    font-family:PingFangSC-Medium,PingFang SC;
  }
  .listBox>.listContent{
    height: 610px;
    overflow: auto;
  }
  .listBox ul{
     overflow: hidden;
    box-sizing: border-box;
   }
  .listBox ul>li{
    float: left;
    padding:0 5px;
    height: 60px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    word-break: break-all;
  }
  .listContent>.listItem{
    background-color: white;
    font-size: 14px;
    font-weight: 500;
    color: #4B4B4B;
    border-bottom: 1px #F0F2F5 solid;
    padding: 0 30px;
  }
  .listBox ul>li>span>span>.row{
    color: #aaaaaa;
  }

  .listBox>.tooltip>div:nth-of-type(1){
    line-height: 70px;
  }
  .listBox>.page{
    width: 100%;
    height: 70px;
    font-size: 14px;
    font-weight:400;
    color:rgba(0,0,0,0.65);
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .listBox>.page>img{
    vertical-align: middle;
  }
  .listBox>.page>input{
    display: inline-block;
    width:42px;
    height:24px;
    line-height: 24px;
    background:rgba(255,255,255,1);
    border-radius:4px;
    border:1px solid rgba(217,217,217,1);
    margin: 0 7px 0 4px;
    text-align: center;
    font-size: 14px;
    font-weight:400;
    color:rgba(0,0,0,0.65);
  }
  .listBox>.page>span:nth-of-type(2){
    margin: 0 4px 0 7px;
  }
  .listBox>.page>img{
    cursor: pointer;
  }
  .cursor_pointer{
    cursor: pointer;
  }

</style>
