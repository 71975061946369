<template>
    <div class="sendScenes" v-show="show">
        <div class="header">
            <button type="create" class="fr" @click="saveConfigEvent">保存配置</button>
            <button type="back" class="fr" @click="backEvent">返回直播间</button>
        </div>
        <div class="scenes fl">
            <div v-for="(v,key) in data.info" :key="key" v-text="v" @click="sceneEvent(v)"></div>
        </div>
        <div class="config fl">
            <template v-for="(v,key) in joiningTraders">
                <div v-show="v" :key="key">
                    <div class="joiningTrader txt_black f20">
                        <label><input ref="joiningTraderCheckbox" type="checkbox" v-model="v.checked"
                                      @change="joiningTraderCheckboxChange(key,$event)">
                            <span v-text="v && v.joiningTrader"></span></label>
                    </div>
                    <div v-for="(v,storekey) in v.store" :key="storekey" class="stores">
                        <div class="store txt_333333 f18">
                            <label><input ref="storeCheckbox" type="checkbox" v-model="v.checked"
                                          @change="storeCheckboxChange(key,storekey,$event)">
                                <span v-text="v.name"></span></label>
                        </div>
                        <div v-for="(roomV,roomKey) in v.room" :key="roomKey" class="room txt_666666 f16 fl">
                            <label><input type="checkbox" v-model="roomV.checked" @change="roomCheckboxChange(key)"><span
                                    v-text="roomV.name"></span></label>
                            <span class="status iconfont icon-xitongzhuangtai f20 cursor_pointer txt_207afb"
                                  @click="statusEvent(roomV,v)"></span>
                        </div>
                    </div>
                </div>
            </template>

        </div>
        <div class="status fr">
            <div class="title txt_333333 f20" v-text="statusRoom"></div>
            <div class="title txt_333333 f16">开关状态</div>
            <div class="buttons">
                <template v-if="switcherStatus.length>0">
                    <div v-for="(v, key) in switcherStatus" :key="key" v-text="v.name" :class="v.status && 'active'"></div>
                </template>
                <span v-else>----暂无数据----</span>
            </div>
            <div class="title txt_333333 f16">继电器状态</div>
            <div class="buttons">
                <template v-if="relayStatus.length>0">
                    <div v-for="(v, key) in relayStatus" :key="key" v-text="v.name" :class="v.status && 'active'"></div>
                </template>
                <span v-else>----暂无数据----</span>
            </div>
            <div class="title txt_333333 f16">IO状态</div>
            <div class="buttons">
                <template v-if="ioStatus.length>0">
                    <div v-for="(v, key) in ioStatus" :key="key" v-text="v.name" :class="v.status && 'active'"></div>
                </template>
                <span v-else>----暂无数据----</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "sendScene",
        data() {
            return {
                show: false,
                data: {},
                joiningTraders: [],
                switcherStatus: [],
                relayStatus: [],
                ioStatus: [],
                statusRoom: '请选择包间'
            }
        },
        mounted() {
        },
        methods: {
            init(data) {
                this.show = true;
                this.data = data;
                this.getJoiningTrader();
            },
            getJoiningTrader() {
                this.$func.getAllData('/manage/franchisee', data => {
                    data.forEach((item, index) => {
                        let store = [], count = 1, len = item.shop.length;
                        this.joiningTraders[index] = {};
                        item.shop.forEach((item1, index1) => {
                            this.$func.getAllData('/manage/store_room', data => {
                                store[index1] = {
                                    name: item1.shop_name, refIndex: index1, checked: false, room: data.map(item2 => {
                                        let config = this.data.config || [];
                                        return {
                                            id: item2.room_id,
                                            name: item2.room_name,
                                            checked: config.includes(item2.room_id)
                                        }
                                    })
                                };
                                if (count === len) {
                                    this.$set(this.joiningTraders, index, {
                                        joiningTrader: item.name,
                                        refIndex: index,
                                        checked: true,
                                        store: store
                                    });
                                    this.$nextTick(() => {
                                        this.setCheckboxStatus(index);
                                    })
                                }
                                count++;
                            }, {store_id: item1.shop_id}, '获取包间失败');
                        });
                    })
                }, null, '获取直播间失败');
            },
            saveConfigEvent() {
                let config = [];
                this.joiningTraders.forEach(item => {
                    item.store.forEach(item1 => {
                        item1.room.forEach(item2 => {
                            if (item2.checked) {
                                config.push(item2.id);
                            }
                        })
                    })
                });
                this.data.config = config;
                this.$api.put('/manage/liveroom/' + this.data.id, this.data).then(() => {
                    this.$alert.success({content: '保存配置成功'});
                }).catch((error) => {
                    this.$alert.error({content: '保存配置失败:' + error});
                });
            },
            initCheckboxStatus() {
                this.joiningTraders.forEach((item, index) => {
                    this.setCheckboxStatus(index);
                })
            },
            setCheckboxStatus(index) {
                let jtIpts = this.$refs.joiningTraderCheckbox, storeIpts = this.$refs.storeCheckbox;
                this.joiningTraders[index].store.forEach((item) => {
                    let every = item.room.every(item => item.checked);
                    let some = item.room.some(item => item.checked);
                    if (every) {
                        item.checked = true;
                        storeIpts[item.refIndex].indeterminate = false;
                    } else if (some) {
                        storeIpts[item.refIndex].indeterminate = true;
                    } else {
                        item.checked = false;
                        storeIpts[item.refIndex].indeterminate = false;
                    }
                });
                let every = this.joiningTraders[index].store.every(item => item.checked && !storeIpts[item.refIndex].indeterminate);
                let some = this.joiningTraders[index].store.some(item => item.checked || storeIpts[item.refIndex].indeterminate);
                if (every) {
                    this.joiningTraders[index].checked = true;
                    jtIpts[this.joiningTraders[index].refIndex].indeterminate = false;
                } else if (some) {
                    jtIpts[this.joiningTraders[index].refIndex].indeterminate = true;
                } else {
                    this.joiningTraders[index].checked = false;
                    jtIpts[this.joiningTraders[index].refIndex].indeterminate = false;
                }
            },
            joiningTraderCheckboxChange(index, event) {
                let checked = event.target.checked, storeIpts = this.$refs.storeCheckbox;
                event.target.indeterminate = false;
                this.joiningTraders[index].store.forEach(item => {
                    item.checked = checked;
                    storeIpts[item.refIndex].indeterminate = false;
                    item.room.forEach(item => {
                        item.checked = checked;
                    })
                })
            },
            storeCheckboxChange(jtIndex, sIndex, event) {
                let checked = event.target.checked;
                event.target.indeterminate = false;
                this.joiningTraders[jtIndex].store[sIndex].room.forEach(item => {
                    item.checked = checked;
                })
                this.setCheckboxStatus(jtIndex);
            },
            roomCheckboxChange(jtIndex) {
                this.setCheckboxStatus(jtIndex);
            },
            sceneEvent(data) {
                let config = [];
                this.joiningTraders.forEach(item => {
                    item.store.forEach(item1 => {
                        item1.room.forEach(item2 => {
                            if (item2.checked) {
                                config.push(item2.id);
                            }
                        })
                    })
                });
                if (config.length <= 0) {
                    this.$modal({content: '请选择需要发送场景的包间'});
                    return;
                }
                this.$api.post('/manage/sendScene', {
                    name: data,
                    id: config
                }).then(() => {
                    this.$alert.success({content: '发送场景成功'});
                }).catch((error) => {
                    this.$alert.error({content: '发送场景失败:' + error});
                });
            },
            statusEvent(data,storeData) {
                this.statusRoom = storeData.name + '·' + data.name;
                const getStatus = () => {
                    this.$api.get('/manage/boxinfo', {
                        room_id: data.id
                    }).then((data) => {
                        this.switcherStatus = data.filter(item => item.type === 1);
                        this.relayStatus = data.filter(item => item.type === 2);
                        this.ioStatus = data.filter(item => item.type === 3);
                    }).catch((error) => {
                        this.$alert.error({content: '获取状态失败:' + error});
                    });
                };
                clearInterval(this.statusTimer);
                getStatus();
                this.statusTimer = setInterval(getStatus, 1000);
            },
            backEvent() {
                this.show = false;
                this.$emit('callback', 'live');
            },
        }
    }
</script>

<style scoped>
    .sendScenes {
        padding: 0 50px;
    }

    .header {
        width: 100%;
        height: 80px;
        padding: 20px 0;
    }

    .header > button {
        margin-left: 20px;
    }

    .scenes {
        width: 40%;
    }

    .config {
        width: 35%;
        padding: 0 25px;
    }

    .status {
        width: 25%;
    }

    .scenes > div {
        width: 120px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        float: left;
        background-color: #207afb;
        color: white;
        cursor: pointer;
        border-radius: 7px;
        margin: 0 20px 20px 0;
    }

    .scenes > div:active {
        background-color: black;
        color: white;
    }

    .config > div {
        overflow: hidden;
    }

    .config label {
        margin-right: 5px;
        cursor: pointer;
    }
    .config .status:active {
        opacity: .5;
    }
    .config label > input {
        margin-right: 5px;
    }

    .config .joiningTrader, .config .store, .config .room {
        margin-bottom: 10px;
        margin-right: 15px;
    }

    .config .stores {
        padding-left: 40px;
        overflow: hidden;
    }

    .config .store {
        margin-left: -20px;
    }

    .stores .item label {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .status > .buttons {
        overflow: hidden;
        margin-top: 10px;
    }

    .status > .buttons > div {
        float: left;
        width: 80px;
        height: 32px;
        line-height: 32px;
        color: white;
        text-align: center;
        border-radius: 5px;
        background-color: #4B4B4B;
        font-size: 12px;
        margin: 0 10px 10px 0;
    }

    .status > .buttons > .active {
        color: white;
        background-color: #207afb;
    }
</style>