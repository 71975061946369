<template>
  <div class="popup" v-show="boxShow">
    <div class="roomDetail bg_white">
      <img class="closer cursor_pointer" src="/images/comm/icon-close.png" alt="" @click="boxShow=false" />
      <div class="title f18 bold500 txt_black87 tc">
        <span v-if="flag==='create'">新建包间</span>
        <span v-if="flag==='view'">查看包间</span>
        <span v-if="flag==='edit'">编辑包间</span>
      </div>

        <div class="content overflow_hidden">
          <div class="mask" v-show="flag==='view'"></div>
          <div class="left fl">
            <div class="item txt_black54 f14 bold500">
              <div class="title bold600 content-center"><span class="iconfont icon-bixuan f20"></span>包间名称：</div>
              <div class="ipt">
                <input class="w100 h100 txt_black54" type="text" placeholder="请输入包间名称" v-model="data.room_name"/>
              </div>
            </div>

            <div class="item txt_black54 f14 bold500">
              <div class="title bold600 content-center"><span class="iconfont icon-bixuan f20"></span>包间类型：</div>
              <div class="ipt">
                <selection :options="$config.roomType.getItem(1)" v-model="room_type"></selection>
              </div>
            </div>

            <div class="item txt_black54 f14 bold500">
              <div class="title bold600 content-center"><span class="iconfont icon-bixuan f20"></span>包间大小：(如：7-9人)</div>
              <div class="ipt">
                <input class="w100 h100 txt_black54" type="text" placeholder="请输入包间大小" v-model="data.room_size"/>
              </div>
            </div>

          <div class="item txt_black54 f14 bold500">
            <div class="title bold600 content-center"><span class="iconfont icon-bixuan f20"></span>网关ID：</div>
            <div class="ipt">
              <input class="w100 h100 txt_black54" type="text" placeholder="请输入网关ID" v-model="data.gatewayid"/>
            </div>
          </div>
       </div>
          <div class="right fr">
            <div class="item txt_black54 f14 bold500">
              <div class="title bold600 content-center"><span class="iconfont icon-bixuan f20"></span>分时段计费单价：<span class="bold400 f12">（<span v-text="business_hours[0]+'-'+business_hours[1]"></span>,暂只支持整点）</span></div>
              <div class="overflow_hidden timeFlameBox">
                <div class="fl" v-for="(v,key) in time_price" :key="key">
                  <div class="fl f14 bold400 txt_black54">
                    <span class="fl">起：</span>
                    <input class="txt_black54 f14 bold400 fl" type="time" v-model="v.start_time" disabled @change="timeChange(v,'start_time')"/>
                    <span class="fl">至：</span>
                    <input class="txt_black54 f14 bold400 fl" type="time" v-model="v.end_time" :disabled="key!==time_price.length-1" @change="timeChange(v,'end_time')"/>
                  </div>
                  <div class="fl">
                    <input class="txt_black54 f14 bold400 fl" type="number" min="0" v-model="v.price"/>
                    <div class="fl tc">元</div>
                  </div>
                </div>
                <div class="addReduce fl" >
                  <img class="fl cursor_pointer" src="/images/comm/add.png" alt="" @click="timeAddEvent"/>
                  <img class="fl cursor_pointer" src="/images/comm/reduce.png" alt="" @click="timeReduceEvent" />
                </div>
              </div>
            </div>
          </div>
        </div>

      <div class="buttons">
        <div class="fl cursor_pointer f14 bold400 txt_4673E3" @click="boxShow=false">取消</div>
        <button class="fr cursor_pointer bg_E91E63 f14 bold400 txt_white tc" @click="confirmEvent">确定</button>
      </div>

    </div>
  </div>

</template>

<script>
  export default {
    name: "roomDetail",
    data() {
      return {
        boxShow: false,
        flag: 'create',
        business_hours: [],
        time_price: [{start_time: '', end_time: '', price: ''}],
        room_type: '',
        data: {
          room_id: null,
          room_name: '',
          room_type: '',
          room_size: '',
          gatewayid: '',
        },
      }
    },
    methods:{
      init(store_id,business_hours,flag,data) {
        this.flag = flag;
        this.business_hours = business_hours.split('-');
        if(this.flag === 'create'){
          for(let v in this.data){
            this.data[v] = '';
          }
          this.room_type = '';
          this.time_price = [{start_time: timeFormat(this.business_hours[0]), end_time: timeFormat(this.business_hours[1]), price: ''}];
        }else{
          for(let v in this.data){
            if(data[v] !== undefined && data[v] !== null){
              this.data[v] = data[v];
            }
          }
          this.room_type = this.$config.roomType.get(data.room_type);
          this.time_price = data.time_price.map(item=>{
            let arr = item.time.split(' - ');
            return {
              start_time: timeFormat(arr[0]),
              end_time: arr[1],
              price: item.price
            }
          });
        }
        this.data.store_id = store_id;
        this.boxShow = true;
        function timeFormat(time){
          if(time.length < 5){
            return '0' + time;
          }
          return time;
        }
      },
      timeChange(data,attr) {
        data[attr] = data[attr].split(':')[0] + ':00'
      },
      confirmEvent() {
        if(this.flag == 'view') {
          this.boxShow = false;
          return;
        }
        if(!this.judgeTimePrice()) return;
        if(!this.data.room_name || !this.room_type || !this.data.room_size || !this.time_price[0].start_time || !this.time_price[0].end_time || !this.time_price[0].price){
          this.$modal({content: '请填写完整数据'});
          return;
        }

        this.data.room_type = this.$config.roomType.get(this.room_type);
        this.data.time_price = this.timePrice2StrTime();
        if(this.flag === 'create'){
          this.$api.post('/manage/store_room',this.data).then(() => {
            this.boxShow = false;
            this.$emit('successCallback');
            this.$alert.success({content: '新建包间成功'});
          }).catch(() => {
            this.$alert.error({content: '新建包间失败'});
          })
        }else{
          this.$api.put('/manage/store_room/'+this.data.room_id,this.data).then(() => {
            this.boxShow = false;
            this.$emit('successCallback');
            this.$alert.success({content: '修改包间成功'});
          }).catch(() => {
            this.$alert.error({content: '修改包间失败'});
          })
        }
      },
      judgeTimePrice() {
        let timePrice = this.time_price,len = timePrice.length;
        for(let i=0;i<len;i++) {
          if(!timePrice[i].start_time || !timePrice[i].end_time || !timePrice[i].price){
            this.$modal({content: '时间计费单价请填写完整'});
            return false;
          }
        }
        if(parseInt(timePrice[len-1].end_time) !== parseInt(this.business_hours[1])){
          this.$modal({content: '时间计费单价须与营业时间一致'});
          return false;
        }
        return true;
      },
      timePrice2StrTime() {
        let len = this.time_price.length;
        if(!this.time_price[len-1].start_time || !this.time_price[len-1].end_time || !this.time_price[len-1].price){
          this.timeReduceEvent();
        }
        return this.time_price.map(item =>{
          return {
            time: item.start_time + ' - ' + item.end_time,
            price: item.price
          }
        });
      },
      timeAddEvent() {
        let time_price = this.time_price[this.time_price.length-1];
        if(!time_price.start_time || !time_price.end_time || !time_price.price){
          this.$modal({content: '请完善时间段和价格'});
          return;
        }
        if(parseInt(time_price.end_time) <= parseInt(time_price.start_time)){
          this.$modal({content: '结束时间须要大于起始时间'});
          return;
        }
        this.time_price.push({
          start_time:  time_price.end_time,
          end_time: time_price.end_time,
          price: ''
        })
      },
      timeReduceEvent (){
        let len = this.time_price.length;
        if(len <= 1)
          return;
        this.time_price.splice(len-1,1);
      },
    }
  }
</script>

<style scoped>
  .roomDetail{
    width: 800px;
  }

  .content>.left {
    width: 46%;
  }
  .content>.right{
    width: 50%;
  }

  .timeFlameBox {
    height: 275px !important;
    overflow: auto;
  }
  .timeFlameBox>div{
    margin-top: 8px;
  }
  .timeFlameBox>.addReduce{
    margin-left: 0 !important;
  }
  .timeFlameBox>.addReduce>img{
    width: 28px;
    height: auto;
    margin-left: 5px;
    margin-top: 3px;
  }
  .timeFlameBox>div>div:nth-of-type(1){
    line-height: 34px;
  }
  .timeFlameBox>div>div:nth-of-type(1)>input{
    width: 70px;
    line-height: 20px;
    padding: 6px 0;
    outline: medium;
  }
  .timeFlameBox>div>div:nth-of-type(2){
    border-radius:3px;
    border:1px solid rgba(204,204,204,1);
    width: 100px;
    height: 34px;
    line-height: 32px;
  }
  .timeFlameBox>div>div:nth-of-type(2)>input{
    width: 48px;
    height: 32px;
    padding-left: 5px;
    outline: medium;
  }
  .timeFlameBox>div>div:nth-of-type(2)>div{
    width: 50px;
    height: 32px;
    background:rgba(247,247,247,1);
    border-radius:4px 0 0 4px;
  }

  .radioBox{
    margin-top: 8px;
    height: 36px;
    line-height: 36px;
  }
  .radioBox>div:nth-of-type(1){
    margin-right: 33px;
  }
  .radioBox>div>input{
    vertical-align:middle;
  }
</style>
