<template>
    <div class="popup" v-show="show">
        <div class="detail bg_white">
            <div class="title f18 bold500 txt_black87 tc">配置场景</div>

            <div class="content">
                <div class="item txt_black54 f14 bold500">
                    <div class="title bold600 content-center">
                        <span class="iconfont icon-bixuan f20"></span>
                        场景名（用"/"隔开）：
                    </div>
                    <div class="ipt">
                        <textarea class="w100 h100 txt_black54 f14 " placeholder="请输入场景昵称" cols="30" rows="10" v-model="scene"></textarea>
                    </div>
                </div>
            </div>

            <div class="buttons">
                <div class="fl f14 txt_4673E3 bold400 cursor_pointer" @click="show=false">取消</div>
                <button class="fr f14 txt_white bold400 tc cursor_pointer bg_E91E63" @click="submitEvent">确认</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "",
        data() {
            return {
                show: false,
                data: {},
                scene: ''
            }
        },
        methods:{
            init(data) {
                this.show = true;
                this.data = data;
                this.scene = data.info.join('/');
            },
            submitEvent() {
                this.data.info = this.scene.split('/');
                this.$api.put('/manage/liveroom/' + this.data.id, this.data).then(() => {
                    this.$alert.success({content: '场景配置成功'});
                    this.show = false;
                    this.$emit('successCallback');
                }).catch((error) => {
                    this.$alert.error({content: '场景配置失败:' + error});
                });
            }
        }
    }
</script>

<style scoped>
    .detail {
        width: 500px;
    }

    .content > .left, .content > .right {
        width: 48%;
    }

    .item > div {
        height: auto !important;
    }

    .item textarea {
        border: 1px solid rgba(204, 204, 204, 1);
        line-height: 20px;
        padding: 8px 10px !important;
        width: 100%;
        height: 120px !important;
    }
</style>
