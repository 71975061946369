<template>
    <div class="header bg_409eff">
        <div class="logo fl">
            <img src="/images/comm/logo.png" alt=""/>
        </div>
        <ul class="fl">
            <template v-for="(v,key) in navs">
                <li v-if="v.show" :key="key" class="fl tc f20 cursor_pointer f14 txt_white"
                    :class="currentNav===v.router&&'active'" @click="navEvent(v.router)" v-text="v.text"></li>
            </template>
        </ul>
        <div class="user fr cursor_pointer" @click.stop="userOptions=!userOptions">
            <span class="iconfont icon-yonghu txt_white f20"></span>
            <span class="txt_white f16" v-text="phone"></span>

            <div class="options bg_white" v-show="userOptions">
                <div @click="loginoutEvent">
                    <!--          <Icon type="md-log-out" size="14" />-->
                    <span class="f12 bold500">退出登录</span>
                </div>
                <div @click="$refs.changePassword.init()">
                    <!--          <Icon type="md-key" size="14" />-->
                    <span class="f12 bold500">修改密码</span>
                </div>
            </div>
        </div>
        <change-password ref="changePassword"></change-password>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import ChangePassword from './changePassword'

    export default {
        name: "homeHeader",
        components: {ChangePassword},
        data() {
            return {
                userOptions: false,
                currentNav: '/home',
                navs: [
                    {router: '/home', text: '首页', show: false, roles: ['admin', 'live', 'store']},
                    {router: '/store', text: '门店', show: false, roles: ['admin']},
                    {router: '/live', text: '直播间', show: false, roles: ['admin', 'live', 'store']},
                    {router: '/liveAccount', text: '直播账户', show: false, roles: ['admin', 'store']},
                    {router: '/joiningTrader', text: '加盟商', show: false, roles: ['admin', 'store']},
                    {router: '/maintainer', text: '维护人员', show: false, roles: ['admin', 'store']}
                ]
            }
        },
        computed: {
            ...mapGetters({
                phone: 'user/phone',
                role: 'user/role',
            }),
        },
        created() {
            this.initNavs();
            document.body.addEventListener('click', this.closeLogout, false);
        },
        destroyed() {
            document.body.removeEventListener('click', this.closeLogout);
        },
        methods: {
            initNavs() {
                this.navs.forEach(item => {
                    item.show = item.roles.includes(this.role);
                })
            },
            navEvent(router) {
                this.currentNav = router;
                this.$router.push({
                    path: router
                })
            },
            closeLogout() {
                this.userOptions = false;
            },
            loginoutEvent() {
                this.userOptions = false;
                this.$api.post('/manage/loginout', {
                    phone: this.phone
                }).then((data) => {
                    if (!data) {
                        this.$store.commit('user/setLogin', {status: false});
                        this.$router.push({path: '/login'});
                    } else {
                        this.$alert.warning({content: data});
                    }
                }).catch(() => {
                    this.$alert.error({content: '退出登录出错，请重试'});
                })
            }
        },
        watch: {
            'role'() {
                this.initNavs();
            }
        }
    }
</script>

<style scoped>
    .header {
        width: 100%;
        height: 64px;
        box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
    }

    .logo > img {
        font-size: 0;
        height: 50px;
        width: auto;
        margin: 7px 0 0 20px;
    }

    .header > ul {
        margin-left: 10%;
    }

    .header > ul > li {
        height: 64px;
        line-height: 64px;
        box-sizing: border-box;
        padding: 0 20px;
    }

    .header > ul > li:hover {
        background-color: #337ecc;
    }

    .header > ul > .active {
        border-bottom: 2px white solid;
    }

    .user {
        height: 64px;
        line-height: 64px;
        margin-right: 24px;
        color: rgba(0, 0, 0, 0.65);
        position: relative;
    }

    .user > .options {
        position: absolute;
        top: 64px;
        right: 0;
        width: 120px;
        line-height: 35px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
        border-radius: 4px;
        padding: 8px 0;
        z-index: 10;
    }

    .user > .options > div {
        padding: 0 15px;
        color: #818181;
    }

    .user > .options > div:hover {
        background: #409eff;
        color: white;
    }
</style>
