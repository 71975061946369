<template>
  <div class="bgBox">
    <div class="box bg_white">
      <div class="circle"></div>
      <div class="leftBox fl">
        <h3 class="txt_white f36">Hello</h3>
        <p class="txt_white f24">欢迎登录动感单车管理系统</p>
        <p class="txt_white f20">科技改变生活</p>
        <img src="/images/comm/img_sign_in.png" alt="">
      </div>
      <div class="rightBox bg_white fl">
        <img src="/images/comm/logo.png" alt="" />
        <div>
          <img class="fl" src="/images/comm/icon_account.png" alt="" />
          <div class="overflow_hidden f14 txt_AAA">
            <form onsubmit="return false;">
              <input type="text" maxlength="11" placeholder="手机号..." v-model="phone" autocomplete="off"  @keyup.enter="loginEvent" />
            </form>
          </div>
        </div>
        <div>
          <img class="fl" src="/images/comm/icon_password.png" alt="" />
          <div class="overflow_hidden f14 txt_AAA">
            <form onsubmit="return false;">
              <input type="password" name="password" placeholder="密码..." v-model="password" autocomplete="off" @keyup.enter="loginEvent" />
            </form>
          </div>
        </div>
        <button class="tc bg_409eff txt_white f15 w100 buttonLogin cursor_pointer" @click="loginEvent">登录</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "index",
    data() {
      return {
        phone: '',
        password: '',
      }
    },
    created() {
      this.init();
    },
    methods:{
      init() {
          let loginTime = window.localStorage.getItem('loginTime');
          let phone = window.localStorage.getItem('phone');
          let password = window.localStorage.getItem('password');
          let role = window.localStorage.getItem('role');
          loginTime = loginTime ? parseInt(loginTime) : 0;
          let nowTime = new Date().getTime();
          if(nowTime - loginTime <= 7200000){
            this.$store.commit('user/setLogin',{status: true,phone : phone,password: password,role: role,loginTime: loginTime});
            this.$router.push({path: '/'});
          }else if(phone && password){
            this.phone = phone;
            this.password = password;
          }
      },
      loginEvent() {
        if(!this.phone || !this.password){
          this.$modal({content: '务必填写手机号和密码'});
          return;
        }
        this.$api.post('/manage/login',{
          phone: this.phone,
          password: this.password
        }).then((data) => {
          let loginTime = new Date().getTime();
          this.$store.commit('user/setLogin',{status: true,phone : this.phone,password: this.password,role: this.$config.roleType.get(data.type),loginTime: loginTime});
          this.$router.push({path: '/'});
        }).catch((error) => {
          this.$alert.error({content: '登录出错:' + error});
        })
      },
    }
  }
</script>

<style scoped>
  .bgBox {
    width: 100%;
    height: 100vh;
    background-color: #f1f1f1;
  }
  .box{
    width: 920px;
    height: 470px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -235px;
    margin-left: -460px;
    border-radius: 6px;
  }
  input{
    outline: medium;
  }
  .circle{
    width:109px;
    height:109px;
    background:linear-gradient(203deg,rgba(229,234,239,1) 0%,rgba(186,202,217,1) 100%);
    opacity:0.48;
    position: absolute;
    left: -68px;
    top: -55px;
    border-radius: 50%;
    z-index: -1;
  }
  .leftBox{
    width: 420px;
    height: 100%;
    position: relative;
    /*background:linear-gradient(198deg,rgba(234,94,89,1) 0%,rgba(233,30,99,1) 100%);*/
    background-color: #207afb;
  }
  .leftBox>p:nth-of-type(1){
    margin: 6px 0 14px 74px;
  }
  .leftBox>h3,.leftBox>p{
    margin-left: 74px;
  }
  .leftBox>h3{
    margin-top: 84px;
  }
  .leftBox>img{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
  }
  .rightBox{
    width: 500px;
    height: 100%;
    padding: 84px 85px 0 85px;
  }
  .resetPasswordBox{
    padding: 10px 85px 0 85px;
  }
  .rightBox>div{
    margin-top: 38px;
  }
  .rightBox>div{
    height: 36px;
    border-bottom: 3px #207afb solid;
  }
  .rightBox>div>img{
    margin-right: 17px;
  }
  .rightBox>div>div input{
    width: 100%;
    height: 24px;
    line-height: 24px;
  }
  .forgetPassword{
    margin: 9px 0 66px 0;
  }
  .buttonLogin{
    height: 38px;
    line-height: 38px;
    margin-top: 70px;
  }

</style>
