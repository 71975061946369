import api from '../api'
import {alert} from '../prompt'

export default {
  getAllData: (path,callback,filter,error) => {
    let data = [];
    filter = filter || {};
    filter.page = 1;
    filter.prepage = 10;
    request();
    function request() {
      api.get(path,filter).then((res) => {
        data.push(...res.data);
        if(res.current < res.total) {
          filter.page += 1;
          request();
        }else {
          callback(data);
        }
      }).catch(() => {
        alert.error({content: error});
      });
    }
  }
}
