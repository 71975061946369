<template>
  <div class="popup" v-show="boxShow">
    <div class="scene bg_white">
      <div class="title f18 bold500 txt_black87 tc">
        <span v-text="title"></span>
      </div>
      <div class="content">
        <div class="f14 bold600 txt_black54">提示：依次填入（<span v-text="contentTitle"></span>）</div>
        <div class="item fl" v-for="(v,key) in scene" :key="key">
          <template v-if="type==='store'">
            <input class="fl f14 bold400 txt_black54" type="text" v-model="v.name" />
            <input class="fl f14 bold400 txt_black54" type="text" v-model="v.scene" />
            <input class="fl f14 bold400 txt_black54" type="text" v-model="v.modelIo" />
          </template>
          <template v-if="type==='room'">
            <input disabled class="room fl f14 bold400 txt_black54" type="text" v-model="v.name" />
            <input class="room fl f14 bold400 txt_black54" type="text" v-model="v.nickname" />
            <input :disabled="key < 8" class="room fl f14 bold400 txt_black54" type="number" v-model="v.scene" min="0" max="99"/>
            <input class="room fl f14 bold400 txt_black54" type="number" v-model="v.modelIo" min="0" max="99" />
          </template>
          <template v-if="type!=='store'&&type!=='room'">
            <input class="fl f14 bold400 txt_black54" type="text" v-model="v.name" />
            <input class="fl f14 bold400 txt_black54" type="text" v-model="v.module_id" />
            <input class="fl f14 bold400 txt_black54" type="text" v-model="v.did" />
          </template>
        </div>
        <div class="addReduce fr content-center">
          <img class="cursor_pointer" src="/images/comm/add.png" alt="" @click="addEvent"/>
          <img v-if="type!=='room' || scene.length > 8" class="cursor_pointer" src="/images/comm/reduce.png" alt="" @click="reduceEvent" />
        </div>
      </div>
      <div class="buttons">
        <div class="fl cursor_pointer f14 bold400 txt_4673E3" @click="boxShow=false">取消</div>
        <button class="fr cursor_pointer bg_E91E63 f14 bold400 txt_white tc" @click="submitEvent">提交</button>
        <button class="fr cursor_pointer bg_4673E3 f14 bold400 txt_white tc" @click="sceneClear">清除场景</button>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: "scene",
    data() {
      return {
        title: '',
        contentTitle: '',
        boxShow: false,
        type: '',
        id: null,
        path: '',
        scene: [],
      }
    },
    methods:{
      init(type,id,title,path,contentTitle) {
        this.boxShow = true;
        this.type = type;
        this.id = id;
        this.title = title;
        this.path = path;
        this.contentTitle = contentTitle;
        this.getScene();

      },
      getScene() {
        let data;
        if(this.type === 'store') {
          data = {store_id: this.id};
        }else if(this.type === 'room') {
          data = {room_id: this.id};
        }else {
          data = {room_id: this.id, type: this.type};
        }
        this.$api.get(this.path,data).then((data) => {
          this.initData(data);
        }).catch((error) => {
          this.$alert.error({content: '获取场景失败：' + error});
        })
      },
      initData(data) {
        if(this.type === 'store') {
          this.scene = data.length > 0 ? data :  [{name: '', scene: '', modelIo: ''}];
        }else if(this.type === 'room') {
          this.scene = data.length > 0 ? data : [
            {name: '运营', nickname: '运营', scene: '1', modelIo: '0'},
            {name: '停运', nickname: '停运', scene: '2', modelIo: '0'},
            {name: '预插卡', nickname: '预插卡', scene: '3', modelIo: '0'},
            {name: '插卡', nickname: '插卡', scene: '4', modelIo: '0'},
            {name: '延时插卡', nickname: '延时插卡', scene: '5', modelIo: '0'},
            {name: '预取卡', nickname: '预取卡', scene: '6', modelIo: '0'},
            {name: '取卡', nickname: '取卡', scene: '7', modelIo: '0'},
            {name: '延时取卡', nickname: '延时取卡', scene: '8', modelIo: '0'},
            {name: '开门', nickname: '开门', scene: '9', modelIo: '0'},
            {name: '消毒', nickname: '消毒', scene: '10', modelIo: '0'},
            {name: '清洁', nickname: '清洁', scene: '11', modelIo: '0'}
          ];
        }else {
          this.scene = data.length > 0 ? data :  [{name: '', module_id: '', did: ''}];
        }
      },
      addEvent() {
        if(this.type === 'store') {
          this.scene.push({name: '', scene: '', modelIo: ''});
        }else if(this.type === 'room') {
          this.scene.push({name: '其他', nickname: '', scene: '', modelIo: ''});
        }else {
          this.scene.push({name: '', module_id: '', did: ''});
        }
      },
      reduceEvent (){
        let len = this.scene.length;
        if(len > 1){
          this.scene.splice(len-1,1);
        }else {
          for(let v in this.scene[0]) {
            this.scene[0][v] = '';
          }
        }
      },
      sceneClear() {
        this.$api.post('/manage/sceneClear',{
          room_id: this.id
        }).then(() => {
          this.$alert.success({content: '清除场景成功'});
        }).catch((error) => {
          this.$alert.error({content: '清除场景失败：' + error});
        })
      },
      submitEvent() {
        let last = this.scene[this.scene.length-1];
        if((last.name === '' || last.name === null || last.name === undefined) && (last.scene === '' || last.scene === null || last.scene === undefined)
        &&(last.modelIo === '' || last.modelIo === null || last.modelIo === undefined) && (last.nickname === '' || last.nickname === null || last.nickname === undefined)
        && (last.module_id === '' || last.module_id === null || last.module_id === undefined) && (last.did === '' || last.did === null || last.did === undefined)) {
          this.scene.splice(this.scene.length-1,1);
        }
        for(let i=0,len=this.scene.length;i<len;i++) {
          let scene = this.scene[i];
          for(let v in scene) {
            if(scene[v] === undefined || scene[v] === null || scene[v] === ''){
              this.$modal({content: '请完善数据再提交'});
              return;
            }
          }
        }
        let data = (this.type === 'store' || this.type === 'room') ? {scene: this.scene} : {data: this.scene,type: this.type};
        this.type === 'store' ? data.store_id = this.id : data.room_id = this.id;
        this.$api.put(this.path+'/'+this.id,data).then(() => {
          this.$alert.success({content: '提交场景成功'});
          this.$emit('updateData');
          this.boxShow = false;
        }).catch((error) => {
          this.$alert.error({content: '提交场景失败：' + error});
        })
      },
    }
  }
</script>

<style scoped>
  .scene{
    width: 700px;
  }

  .content{
    height: 400px;
    overflow: auto !important;
    padding: 15px !important;
  }

  .content > .item {
    overflow: hidden;
    width: 550px;
    display: flex;
    justify-content: space-around;
  }

  .content > .item > input {
    width: 25%;
    border: none;
    border-bottom: 1px #4B4B4B solid;
    outline: medium;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
  }

  .content > .item > .room {
    width: 20%;
  }

  .content > .addReduce {
    height: 40px;
    line-height: 40px;
    width: 80px;
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
  }

  .buttons > button {
    margin-left: 20px;
  }
</style>
