<template>
  <button @click="clickEvent" :class="disable&&'disable'" :disabled="disable">
    <Icon v-if="loading" type="ios-loading" :size='size' class="demo-spin-icon-load"></Icon>
    <span v-text="text"></span>
  </button>
</template>

<script>
  export default {
    name: "buttonLoading",
    data() {
      return {
        loading: false,
        disable: false
      }
    },
    created() {

    },
    methods: {
      clickEvent() {
        this.loading = true;
        this.disable = true;
        this.$emit('clickEvent',this.eventData,()=>{
          this.loading = false;
          this.disable = false;
        });
      }
    },
    props: {
      text: String,
      eventData: Object,
      size: {
        type: Number,
        default: 16
      }
    }
  }
</script>

<style scoped>
  .demo-spin-icon-load{
    animation: ani-demo-spin 1s linear infinite;
  }
  .disable{
    cursor: not-allowed;
    opacity: .5;
  }
</style>
