<template>
  <div class="alert" :class="[alertData.type,alertData.show?'show':'noShow']">
    <span v-if="alertData.type=='info'" class="icon iconfont icon-info"></span>
    <span v-if="alertData.type=='success'" class="icon iconfont icon-success"></span>
    <span v-if="alertData.type=='warning'" class="icon iconfont icon-warning"></span>
    <span v-if="alertData.type=='error'" class="icon iconfont icon-error"></span>
    <span v-text="alertData.content">测试alert数据</span>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    name: "alert",
    computed: {
      ...mapGetters({
        alertData: 'prompt/alertData'
      }),
    },
  }
</script>

<style scoped>
  .alert{
    position: fixed;
    left: 50%;
    margin-left: -300px;
    width: 600px;
    height: 50px;
    color: #515a6e;
    z-index: 40;
    border-radius: 5px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s linear;
  }
  .noShow {
    top: -50px;
  }
  .show {
    top: 70px;
  }
  .alert > .icon {
    font-size: 24px;
    margin-right: 10px;
  }
  .info {
    background-color: #f0faff;
    border: 1px #abdcff solid;
  }
  .info > .icon {
    color: #2d8cf0;
  }
  .success {
    background-color: #edfff3;
    border: 1px #8ce6b0 solid;
  }
  .success > .icon {
    color: #19be6b;
  }
  .warning {
    background-color: #fff9e6;
    border: 1px #ffd77a solid;
  }
  .warning > .icon {
    color: #ff9900;
  }
  .error {
    background-color: #ffefe6;
    border: 1px #ffb08f solid;
  }
  .error > .icon {
    color: #ed4014;
  }
</style>
