<template>
    <div class="liveAccount bg_white">
        <div class="header">
            <button type="create" class="fr" @click="createEvent">新建</button>
        </div>
        <lists ref="lists" :data="lists" :listsStyle="listsStyle" :httpRequestData="httpRequestData" @deleteEvent="deleteEvent" @editEvent="editEvent"></lists>
        <detail ref="detail" @successCallback="$refs.lists.updateData()"></detail>
    </div>
</template>

<script>
    import Lists from '@/components/Lists'
    import Detail from './detail'
    export default {
        name: "maintainer",
        components: {
            Lists, Detail
        },
        data() {
            return {
                lists: {
                    title: [{text: '账户名称'}, {text: 'openID'}, {text: '体验店'}, {text: '操作'}],
                    content: [],
                    data: []
                },
                listsStyle: [
                    {width: '25%'},
                    {width: '25%'},
                    {width: '25%'},
                    {width: '25%'},
                ],
                httpRequestData: {
                    url: '/manage/serviceman',
                    func: this.httpRequestHandle,
                    error: '获取加盟商信息出错',
                    auto: false
                },
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.$refs.lists.updateData();
            },
            httpRequestHandle(data) {
                let operation = {
                    arr: [
                        {text: '编辑', style: {color: '#1890FF'}, event: 'editEvent'},
                        {text: '删除', style: {color: '#FF2118'}, event: 'deleteEvent'}
                    ]
                };
                return data.map(item =>{
                    return [
                        {text: item.name},
                        {text: item.user.openid},
                        {text: item.shop.map(item => item.shop_name).join(',')},
                        operation
                    ]
                });
            },
            createEvent() {
                this.$refs.detail.init('create');
            },
            editEvent(data) {
                this.$refs.detail.init('edit', data);
            },
            deleteEvent(data) {
                this.$delete({
                    title: '删除加盟商',
                    tooltipContent: '请输入当前加盟商名称以删除当前加盟商,数据将不可恢复，谨慎操作',
                    value: data.name,
                    confirm: () => {
                        this.$api.delete('/manage/serviceman/' + data.id).then(() => {
                            this.$alert.success({content: '删除维护账户成功'});
                            this.$refs.lists.updateData();
                        }).catch((error) => {
                            this.$alert.error({content: '删除维护账户失败:' + error});
                        });
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .liveAccount > .header {
        height: 80px;
        padding: 0 30px;
    }

    .liveAccount > .header > button {
        margin-top: 20px;
    }
</style>
