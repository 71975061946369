import { render, staticRenderFns } from "./sendScene.vue?vue&type=template&id=5c972a77&scoped=true&"
import script from "./sendScene.vue?vue&type=script&lang=js&"
export * from "./sendScene.vue?vue&type=script&lang=js&"
import style0 from "./sendScene.vue?vue&type=style&index=0&id=5c972a77&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c972a77",
  null
  
)

export default component.exports