<template>
  <Modal class-name="vertical-center-modal" class="tc modal" v-model="modalData.show" width="400px">
    <p class="title" slot="header">
      <span class="f20 txt_black85 bold500" v-text="modalData.header"></span>
    </p>
    <div class="body f18 txt_black65 bold400">
      <p v-text="modalData.content"></p>
    </div>
    <div class="footer overflow_hidden" slot="footer">
      <div class="fl txt_4673E3 cursor_pointer" @click="cancelEvent">取消</div>
      <button class="fr bg_E91E63 f18 bold400 txt_white tc cursor_pointer" @click="confirmEvent">确定</button>
    </div>
  </Modal>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    name: "modal",
    computed: {
      ...mapGetters({
        modalData: 'prompt/modalData'
      }),
    },
    methods: {
      cancelEvent() {
        this.modalData.show = false;
        if(this.modalData.cancel){
          this.modalData.cancel();
        }
      },
      confirmEvent() {
        this.modalData.show = false;
        if(this.modalData.confirm){
          this.modalData.confirm();
        }
      }
    }
  }
</script>

<style scoped>
  .modal>>>.vertical-center-modal{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal>>>.ivu-modal{
    top: 0;
  }
  .modal .footer{
    height: 32px;
    line-height: 32px;
  }
  .modal .footer>button{
    width: 100px;
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
  }
  .modal .body{
    min-height: 80px;
  }
</style>
