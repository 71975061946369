<template>
    <div class="popup" v-show="show">
        <div class="detail bg_white">
            <div class="title f18 bold500 txt_black87 tc" v-if="flag==='create'">新建加盟商</div>

            <div class="content">
                <div class="mask" v-if="flag==='view'"></div>
                    <div class="item txt_black54 f14 bold500">
                        <div class="title bold600 content-center">
                            <span class="iconfont icon-bixuan f20"></span>
                            账户名称：
                        </div>
                        <div class="ipt">
                            <input class="w100 h100" type="text" placeholder="请输入账户名" v-model="data.name" />
                        </div>
                    </div>
                <div class="item txt_black54 f14 bold500">
                    <div class="title bold600 content-center">
                        <span class="iconfont icon-bixuan f20"></span>
                        手机号：
                    </div>
                    <div class="ipt">
                        <input class="w100 h100" type="text" placeholder="请输入账户名" v-model="data.phone" />
                    </div>
                </div>
                    <div class="item txt_black54 f14 bold500">
                        <div class="title bold600 content-center">
                            <span class="iconfont icon-bixuan f20"></span>
                            选择门店：
                        </div>
                        <div class="check">
                            <label v-for="(v,key) in stores" :key="key">
                                <input type="checkbox" :checked="v.checked" @change="checkboxChange(key, $event)"><span v-text="v.store_name"></span>
                            </label>
                        </div>
                    </div>
            </div>
            <div class="buttons">
              <div class="fl f14 txt_4673E3 bold400 cursor_pointer" @click="show=false">取消</div>
              <button class="fr f14 txt_white bold400 tc cursor_pointer bg_E91E63" @click="submitEvent">确认</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "",
        data() {
            return {
                show: false,
                flag: 'create',
                stores: [],
                data: {
                    id: '',
                    name: '',
                    phone: '',
                    shop: [],
                }
            }
        },
        methods:{
            init(flag,data) {
                this.show = true;
                this.flag = flag;
                if(flag !== 'create') {
                    for(let v in this.data) {
                        this.data[v] = data[v];
                    }
                }else {
                    for(let v in this.data) {
                        this.data[v] = '';
                    }
                    this.data.shop = [];
                }
                this.getStores();
            },
            getStores() {
                this.$func.getAllData('/manage/store', data =>{
                    this.stores = data.map(item => {
                        let checked = this.data.shop.find(itemShop => item.store_id === itemShop.shop_id);
                        checked = checked !== undefined;
                        return {
                            store_name: item.store_name,
                            store_id: item.store_id,
                            checked: checked
                        }
                    })
                }, null,  '获取门店出错');
            },
            checkboxChange(index, event) {
                this.stores[index].checked = event.target.checked;
            },
            submitEvent() {
                this.data.shop_id = this.stores.filter(item => item.checked).map(item => item.store_id);
                if(this.flag === 'view') {
                  this.show = false;
                }else if(this.flag === 'create'){
                    this.$api.post('/manage/franchisee',this.data).then(() => {
                        this.$alert.success({content: '新建加盟商成功'});
                        this.show = false;
                        this.$emit('successCallback');
                    }).catch((error) => {
                        this.$alert.error({content: '新建加盟商失败:' + error});
                    });
                }else {
                    this.$api.put('/manage/franchisee/' + this.data.id,this.data).then(() => {
                        this.$alert.success({content: '修改加盟商成功'});
                        this.show = false;
                        this.$emit('successCallback');
                    }).catch((error) => {
                        this.$alert.error({content: '修改加盟商失败:' + error});
                    });
                }
            }
        }
    }
</script>

<style scoped>
    .detail {
        width: 600px;
    }

    .content > .left, .content > .right {
        width: 48%;
    }

    .item .check {
        border: 1px solid rgba(204, 204, 204, 1);
        height: 120px !important;
        overflow: auto;
        border-radius: 5px;
        padding: 0 10px;
    }

    .check label {
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        margin-right: 15px;
        cursor: pointer;
    }
    .check label input {
        margin-right: 5px;
    }
</style>
