// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import {alert,modal,deleteConfirm} from './prompt'
import api from './api'
import func from './func'
import App from './App'
import config from './config'
import router from './router'
import store from './store'
import ButtonLoading from '@/components/buttonLoading'
import selection from './components/selection'
import { Cascader, TimePicker, Modal,Checkbox } from 'view-design';
import 'view-design/dist/styles/iview.css';
import './assets/css/comm.css'
import './assets/css/icon.css'

Vue.config.productionTip = true;

Vue.component('button-loading', ButtonLoading);
Vue.component('selection', selection);
Vue.component('Cascader', Cascader);
Vue.component('TimePicker', TimePicker);
Vue.component('Modal', Modal);
Vue.component('Checkbox', Checkbox);

Vue.prototype.$api = api;
Vue.prototype.$config = config;

Vue.prototype.$alert = alert;
Vue.prototype.$modal = modal;
Vue.prototype.$delete = deleteConfirm;
Vue.prototype.$func = func;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
