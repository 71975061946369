<template>
    <div class="popup" v-show="show">
        <div class="detail bg_white">
            <div class="title f18 bold500 txt_black87 tc" v-if="flag==='create'">新建直播账户</div>

            <div class="content">
                <div class="mask" v-if="flag==='view'"></div>
                    <div class="item txt_black54 f14 bold500">
                        <div class="title bold600 content-center">
                            <span class="iconfont icon-bixuan f20"></span>
                            账户名：
                        </div>
                        <div class="ipt">
                            <input class="w100 h100" type="text" placeholder="请输入账户名" v-model="data.name" />
                        </div>
                    </div>

                    <div class="item txt_black54 f14 bold500">
                        <div class="title bold600 content-center">
                            <span class="iconfont icon-bixuan f20"></span>
                            账号（建议手机号）：
                        </div>
                        <div class="ipt">
                            <input class="w100 h100" type="text" placeholder="请输入账号(手机号)" v-model="data.account" />
                        </div>
                    </div>
            </div>
            <div class="buttons">
              <div class="fl f14 txt_4673E3 bold400 cursor_pointer" @click="show=false">取消</div>
              <button class="fr f14 txt_white bold400 tc cursor_pointer bg_E91E63" @click="submitEvent">确认</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "",
        data() {
            return {
                show: false,
                flag: 'create',
                data: {
                    id: '',
                    name: '',
                    account: ''
                }
            }
        },
        methods:{
            init(flag,data) {
                this.show = true;
                this.flag = flag;
                if(flag !== 'create') {
                    for(let v in this.data) {
                        this.data[v] = data[v];
                    }
                }else {
                    for(let v in this.data) {
                        this.data[v] = '';
                    }
                }
            },
            submitEvent() {
                if(this.flag === 'create'){
                    this.$api.post('/manage/liveaccount',this.data).then(() => {
                        this.$alert.success({content: '新建直播账户成功'});
                        this.show = false;
                        this.$emit('successCallback');
                    }).catch((error) => {
                        this.$alert.error({content: '新建直播账户失败:' + error});
                    });
                }else {
                    this.$api.put('/manage/liveaccount/' + this.data.id,this.data).then(() => {
                        this.$alert.success({content: '修改直播账户成功'});
                        this.show = false;
                        this.$emit('successCallback');
                    }).catch((error) => {
                        this.$alert.error({content: '修改直播账户失败:' + error});
                    });
                }
            }
        }
    }
</script>

<style scoped>
    .detail {
        width: 600px;
    }

    .content > .left, .content > .right {
        width: 48%;
    }

    .item textarea {
        border: 1px solid rgba(204, 204, 204, 1);
        line-height: 20px;
        padding: 8px 10px !important;
        height: 116px;
    }
</style>
