import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import login from '@/views/login'
import home from '@/views/home'
import oStore from '@/views/store'
import live from '@/views/live'
import liveAccount from '@/views/liveAccount'
import joiningTrader from '@/views/joiningTrader'
import maintainer from '@/views/maintainer'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            component: home,
            meta: {
                keepAlive: false,
                requiresAuth: true,
                keepHeader: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: login,
            meta: {
                keepAlive: false,
                requiresAuth: false,
                keepHeader: false
            }
        },
        {
            path: '/home',
            name: 'home',
            component: home,
            meta: {
                keepAlive: false,
                requiresAuth: true,
                keepHeader: true
            }
        },
        {
            path: '/store',
            name: 'store',
            component: oStore,
            meta: {
                keepAlive: true,
                requiresAuth: true,
                keepHeader: true
            }
        },
        {
            path: '/live',
            name: 'live',
            component: live,
            meta: {
                keepAlive: true,
                requiresAuth: true,
                keepHeader: true
            }
        },
        {
            path: '/liveAccount',
            name: 'liveAccount',
            component: liveAccount,
            meta: {
                keepAlive: true,
                requiresAuth: true,
                keepHeader: true
            }
        },
        {
            path: '/joiningTrader',
            name: 'joiningTrader',
            component: joiningTrader,
            meta: {
                keepAlive: true,
                requiresAuth: true,
                keepHeader: true
            }
        },
        {
            path: '/maintainer',
            name: 'maintainer',
            component: maintainer,
            meta: {
                keepAlive: true,
                requiresAuth: true,
                keepHeader: true
            }
        },
    ]
});

router.beforeEach((to, from, next) => {
    let loginStatus = store.getters['user/login'];
    if (!loginStatus && to.meta.requiresAuth) {
        next({path: '/login'});
    } else {
        next();
    }
});

export default router;
