<template>
    <div class="storeBox w100 h100">
      <Live ref="live" @callback="callback"></Live>
      <send-scene ref="sendScene" @callback="callback"></send-scene>
    </div>
</template>

<script>
  import Live from './live'
  import SendScene from './sendScene'
  export default {
    name: "live",
    components:{
        Live, SendScene
    },
    methods: {
      callback(node,...args) {
        this.$refs[node].init(...args);
      }
    }
  }
</script>

<style scoped>

</style>
