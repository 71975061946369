<template>
  <div class="homePage bg_white">
    <div class="box device fl bg_white">
      <h3 class="title txt_black f20">设备状态</h3>
      <div class="fr f14">
        <div class="fl statistical"><div :style="{height: (device.num_normal/device.num_all)*100+'%'}"></div></div>
        <div class="txt_a0a0a0 fr tl">正常数</div>
        <div class="txt_black fr num tl" v-text="device.num_normal"></div>
      </div>
      <div class="fr txt_a0a0a0 f14">
        <div class="fl statistical"><div :style="{height: (device.num_all/device.num_all)*100+'%'}"></div></div>
        <div class="txt_a0a0a0 fr tl">总数量</div>
        <div class="txt_black fr num tl" v-text="device.num_all"></div>
      </div>
      <div class="fr txt_a0a0a0 f16">
        <div class="fl statistical"><div :style="{height: (device.num_stop/device.num_all)*100+'%'}"></div></div>
        <div class="txt_a0a0a0 fr tl">停用数</div>
        <div class="txt_black fr num tl" v-text="device.num_stop"></div>
      </div>
      <div class="fr txt_a0a0a0 f16">
        <div class="fl statistical"><div :style="{height: (device.num_offline/device.num_all)*100+'%'}"></div></div>
        <div class="txt_a0a0a0 fr tl">离线数</div>
        <div class="txt_black fr num tl" v-text="device.num_offline"></div>
      </div>
    </div>
    <div class="box status fl bg_white">
      <h3 class="title txt_black f20">物联状态</h3>
      <div class="txt_a0a0a0 f14">
        <template v-for="(v, key) in status">
          <span :key="key*2" v-text="v.name"></span><span :key="key*2+1" class="num txt_207afb f20" v-text="v.all"></span>
        </template>
      </div>
      <div class="statisticalBox">
        <div class="statistical" v-for="(v, key) in status" :key="key">
          <div class="fl" v-text="v.name"></div>
          <div class="fl statusStatistical"><div v-text="v.on"></div><div v-text="v.off"></div><span v-text="v.out"></span></div>
        </div>
      </div>
      <div class="flag">
        <div></div><span>开机</span>
        <div></div><span>关机</span>
<!--        <div></div><span>离线</span>-->
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    name: "home",
    data() {
      return {
        device: {
          num_all: 0,
          num_normal: 0,
          num_offline: 0,
          num_stop: 0
        },
        status: [],
      }
    },
    computed: {
      ...mapGetters({
        role: 'user/role',
      })
    },
    created() {
      this.init();
      this.timer = setInterval(() => {
        this.init();
      }, 3000);

    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
    methods: {
      init() {
        this.getDevice();
        this.getStatus();
      },
      getDevice() {
        this.$api.get('/manage/devices',{
        }).then((data) => {
          this.device = data;
        }).catch(() => {
          this.$alert.error({content: '获取设备状态出错'});
        })
      },
      getStatus() {
        this.$api.get('/manage/infostatus',{
        }).then((data) => {
          this.status = data;
        }).catch(() => {
          this.$alert.error({content: '获取物联状态出错'});
        })
      }
    }
  }
</script>

<style scoped>
  .homePage {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homePage>.box{
    width: 580px;
    height: 350px;
    margin-top: 100px;
    border: #dddddd 1px solid;
    border-radius: 10px;
    display: inline-block;
  }
  .homePage>.box:nth-of-type(1) {
    margin-right: 50px;
  }
  .homePage>.box>.title{
    margin: 10px 0 0 20px;
  }
  .homePage>.device>div{
    width: 180px;
    background-color: #f9fafb;
    height: 100px;
    margin: 20px;
    padding: 10px;
    border-radius: 5px;
  }
  .homePage>.device>div>.num {
    margin-top: 42px;
  }
  .homePage>.device>div>div:nth-of-type(n+2) {
    width: 120px;
  }
  .homePage>.device>div>.statistical {
    width: 20px;
    height: 100%;
    border: #dddddd 1px solid;
    border-radius: 10px;
    background-color: white;
    position: relative;
    overflow: hidden;
  }
  .homePage>.device>div>.statistical>div {
    width: 100%;
    border-radius: 10px;
    background-color: #fe9597;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .homePage>.status>div, .homePage>.status>.statisticalBox>div {
    width: 100%;
    padding: 0 30px;
    margin: 26px 0;
    overflow: hidden;
  }
  .homePage>.status>div>.num {
    margin: 0 40px 0 12px;
  }
  .statisticalBox {
    width: 100%;
    padding: 0 !important;
    margin: -26px 0 -35px 0 !important;
    height: 220px;
    overflow: auto !important;
    /*display: -webkit-box;*/
    /*-webkit-overflow-scrolling: touch;*/
  }
  /*.statisticalBox::-webkit-scrollbar {*/
  /*  width: 0px;*/
  /*}*/

  /*.statisticalBox::-webkit-scrollbar-track {*/
  /*  background-color: none;*/
  /*}*/

  /*.statisticalBox::-webkit-scrollbar-thumb {*/
  /*  background-color: none;*/
  /*}*/

  /*.statisticalBox::-webkit-scrollbar-thumb:hover {*/
  /*  background-color: none;*/
  /*}*/

  /*.statisticalBox::-webkit-scrollbar-thumb:active {*/
  /*  background-color: none;*/
  /*}*/
  .box .statusStatistical {
    width: 400px;
    height: 20px;
    line-height: 20px;
    margin-left: 20px;
    border-radius: 10px;
    background-color: #bdbcbc;
    text-align: right;
    position: relative;
    color: black;
    padding-right: 10px;
    font-size: 14px;
    font-weight: 600;
  }
  .statusStatistical > div {
    height: 100%;
    float: left;
    border-radius: 10px;
    text-align: right;
    position: absolute;
    top: 0;
    left: 0;
    padding-right: 10px;
  }
  .statusStatistical > div:nth-of-type(1) {
    background-color: #95dc73;
    z-index: 2;
    min-width: 50px;
    color: white;
  }
  .statusStatistical > div:nth-of-type(2) {
    background-color: #fe9597;
    z-index: 1;
    min-width: 100px;
    color: white;
  }
  .flag {
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
  }
  .flag > div {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    margin: 0 5px 0 15px;
  }
  .flag > div:nth-of-type(1) {
    background-color: #95dc73;
    margin-left: 0;
  }
  .flag > div:nth-of-type(2) {
    background-color: #fe9597;
  }
  .flag > div:nth-of-type(3) {
     background-color: #bdbcbc;
   }
</style>
