import store from "../store";

export const alert = {
  info(data,unAuto) {
    data.show = true;
    data.type = 'info';
    store.commit('prompt/setAlertData',data);
    AutoCloseAlert(unAuto);
  },
  success(data,unAuto) {
    data.show = true;
    data.type = 'success';
    store.commit('prompt/setAlertData',data);
    AutoCloseAlert(unAuto);
  },
  warning(data,unAuto) {
    data.show = true;
    data.type = 'warning';
    store.commit('prompt/setAlertData',data);
    AutoCloseAlert(unAuto);
  },
  error(data,unAuto) {
    data.show = true;
    data.type = 'error';
    store.commit('prompt/setAlertData',data);
    AutoCloseAlert(unAuto);
  }
};
function AutoCloseAlert(unAuto) {
  if(unAuto) return;
  setTimeout(()=>{
    store.commit('prompt/setAlertData',{});
  },3000)
}

export const modal = (data)=>{
  data.show = true;
  store.commit('prompt/setModalData',data);
};

export const deleteConfirm = (data)=>{
  data.show = true;
  store.commit('prompt/setDeleteData',data);
};

