<template>
    <div class="popup" v-show="show">
        <div class="detail bg_white">
            <div class="title f18 bold500 txt_black87 tc">修改密码</div>

            <div class="content">
                <div class="item txt_black54 f14 bold500">
                    <div class="title bold600 content-center">
                        <span class="iconfont icon-bixuan f20"></span>
                        旧密码：
                    </div>
                    <div class="ipt">
                        <input class="w100 h100" type="text" placeholder="请输入账户名" v-model="old_password"/>
                    </div>
                </div>
                <div class="item txt_black54 f14 bold500">
                    <div class="title bold600 content-center">
                        <span class="iconfont icon-bixuan f20"></span>
                        新密码：
                    </div>
                    <div class="ipt">
                        <input class="w100 h100" type="text" placeholder="请输入账户名" v-model="new_password1"/>
                    </div>
                </div>
                <div class="item txt_black54 f14 bold500">
                    <div class="title bold600 content-center">
                        <span class="iconfont icon-bixuan f20"></span>
                        确认密码：
                    </div>
                    <div class="ipt">
                        <input class="w100 h100" type="text" placeholder="请输入账户名" v-model="new_password2"/>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <div class="fl f14 txt_4673E3 bold400 cursor_pointer" @click="show=false">取消</div>
                <button class="fr f14 txt_white bold400 tc cursor_pointer bg_E91E63" @click="submitEvent">确认</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "",
        data() {
            return {
                show: false,
                old_password: '',
                new_password1: '',
                new_password2: ''
            }
        },
        methods: {
            init() {
                this.show = true;
            },
            submitEvent() {
                if (!this.old_password || !this.new_password1 || !this.new_password2) {
                    this.$modal({content: '密码不能为空'});
                    return;
                }
                if (this.new_password1 !== this.new_password2) {
                    this.$modal({content: '两次密码输入必须相同'});
                    return;
                }
                this.$api.post('/manage/modifyPassword', {
                    old_password: this.old_password,
                    new_password1: this.new_password1,
                    new_password2: this.new_password2
                }).then(() => {
                    this.$alert.success({content: '修改密码成功'});
                    this.show = false;
                }).catch((error) => {
                    this.$alert.error({content: '修改密码失败:' + error});
                });
            }
        }
    }
</script>

<style scoped>
    .detail {
        width: 600px;
    }
</style>
