/*提示框数据*/
const defaultAlertData = {
  type: '',
  closable: true,
  show_icon: false,
  content: '',
  show: false
};
const defaultModalData = {
  show: false,
  header: '提示',
  content: '',
  confirm: ''
};
const defaultDeleteData = {
  show: false,
  title: '',
  tooltip: '危险操作提示',
  tooltipContent: '',
  value: '',
  confirm: ''
};

const state={
  alertData: defaultAlertData,
  modalData: defaultModalData,
  deleteData: defaultDeleteData,
};
const getters={
  alertData: state => state.alertData,
  modalData: state => state.modalData,
  deleteData: state => state.deleteData,
};

const actions={};

const mutations={
  setAlertData(state,data){
    for(let item in defaultAlertData) {
      if(data[item] === undefined){
        data[item] = defaultAlertData[item];
      }
    }
    state.alertData = data;
  },
  setModalData(state,data){
    for(let item in defaultModalData) {
      if(data[item] === undefined){
        data[item] = defaultModalData[item];
      }
    }
    state.modalData = data;
  },
  setDeleteData(state,data){
    for(let item in defaultDeleteData) {
      if(data[item] === undefined){
        data[item] = defaultDeleteData[item];
      }
    }
    state.deleteData = data;
  }
};

export default {
  namespaced:true,
  state,
  getters,
  mutations,
  actions
}
