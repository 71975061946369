import api from '../../api'
const state={
  provinces: [],
  regions: []
};
const getters={
  provinces: state => state.provinces,
  regions: state => state.regions,
};
const actions={
  getProvinces() {
    api.get('/data/provinces.json?v=1',{}).then((data) => {
      this.commit('provinces/setProvinces',data);
      this.commit('provinces/setRegions',data);
    }).catch(() => {
      this.$alert.error({content: '获取省市区出错'});
    })
  }
};

const mutations= {
  setProvinces(state, data) {
    state.provinces = data;
  },
  setRegions(state, data) {
    state.regions = data.map(item => {
      return {
        "value": item.value,
        "label": item.label,
        "children": item.children.map(item1 => {
          return {
            "value": item1.value,
            "label": item1.label,
          }
        })
      }
    });
  }
};

export default {
  namespaced:true,
  state,
  getters,
  mutations,
  actions
}
