<template>
  <div class="deleteBox" v-show="deleteData.show">
    <div class="content">
      <div class="title f18 txt_black87 bold500 tc" v-text="deleteData.title"></div>
      <div class="tooltip content-center"><div class="bg_FF2118 txt_white tc">!</div><span class="f18 txt_black bold600" v-text="deleteData.tooltip"></span></div>
      <div class="tooltipContent f16 bold500 txt_FF2118" v-text="deleteData.tooltipContent"></div>
      <textarea class="bg_FAFAFA txt_black87" cols="30" rows="10" v-model="iptVal"></textarea>
      <div v-show="errorShow"  class="error f14 bold400 txt_FF2118">输入值不匹配，请重新输入</div>
      <div class="button">
        <div class="fl txt_4673E3 f14 bold400 cursor_pointer" @click="closeEvent">放弃删除</div>
        <button class="fr bg_FF2118 tc f14 bold400 txt_white cursor_pointer" @click="deleteEvent">删除</button>
      </div>
<!--      <span class="closer iconfont icon-ios-close-circle f26"></span>-->
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    name: "delete",
    data() {
      return {
        iptVal: '',
        errorShow:false,
      }
    },
    computed: {
      ...mapGetters({
        deleteData: 'prompt/deleteData'
      }),
    },
    methods:{
      deleteEvent() {
        if(this.iptVal === this.deleteData.value){
          this.errorShow = false;
          if(typeof this.deleteData.confirm === "function"){
            this.deleteData.confirm();
          }
          this.closeEvent();
        }else{
          this.errorShow = true;
        }
      },
      closeEvent(){
        this.iptVal = '';
        this.$store.commit('prompt/setDeleteData',{
          show: false,
          title: '',
          tooltipContent: '',
          value: '',
          confirm: ''
        });
      },
    }
  }
</script>

<style scoped>
  .deleteBox{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:rgba(0,0,0,0.64);
    z-index: 30;
  }
  .deleteBox>.content{
    background-color: white;
    width: 376px;
    height: 437px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -188px;
    margin-top: -218.5px;
    box-shadow:0 4px 12px 0 rgba(0,0,0,0.15);
    border-radius: 8px;
  }
  .deleteBox>.content>.title{
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px rgba(0,0,0,0.12) solid;
  }
  .deleteBox>.content>.closer{
    position: absolute;
    top: 14px;
    right: 20px;
  }
  .deleteBox>.content>.tooltip{
    margin: 31px 0 15px 28px;
  }
  .deleteBox>.content>.tooltip>div{
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    margin-right: 11px;
  }
  .deleteBox>.content>.tooltipContent{
    padding: 0 28px;
    line-height: 33px;
  }
  .deleteBox>.content>textarea{
    width: 320px;
    height: 100px;
    margin: 15px 28px 33px 28px;
    border-radius:3px;
    border:1px solid rgba(204,204,204,1);
    outline: medium;
    padding: 2px 5px;
  }
  .deleteBox>.content>.error{
    padding: 0 28px;
  }
  .deleteBox>.content>.button{
    border-top: 1px rgba(0,0,0,0.12) solid;
    width: 100%;
    height: 68px;
    line-height: 68px;
    padding: 0 20px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .deleteBox>.content>.button>button{
    width: 102px;
    height: 34px;
    line-height: 34px;
    border-radius: 3px;
    margin-top: 17px;
  }
</style>
