<template>
  <div id="app">
    <keep-alive v-if="$route.meta.keepHeader">
      <Header></Header>
    </keep-alive>
    <keep-alive v-if="$route.meta.keepAlive">
      <router-view></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <alert></alert>
    <modal></modal>
    <delete></delete>
  </div>
</template>
<script>
  import Header from '@/components/header'
  import Alert from '@/components/alert'
  import Delete from '@/components/delete'
  import Modal from '@/components/modal'
  export default {
    name: 'App',
    components:{
      Header, Alert, Delete, Modal
    },
    created() {
      this.getProvinces();
    },
    methods: {
      getProvinces() {
        this.$store.dispatch('provinces/getProvinces');
      }
    }
  }
</script>

<style>
  html,body{
    width: 100%;
    height: 100%;
    background-color: white;
  }
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    min-height: 100%;
  }
</style>
