<template>
  <div>
    <div class="infoBox bg_white" v-show="boxShow">
      <div class="title">
        <div class="fl f14 bold400 txt_black85">当前体验店：<span v-text="storeNav"></span></div>
        <button type="create" class="fr" @click="createRoomEvent">新建包间</button>
        <button type="back" class="fr" @click="backEvent">返回</button>
      </div>
      <lists ref="lists" :data="lists" :listsStyle="listsStyle" :httpRequestData="httpRequestData" @roomLookEvent="roomLookEvent" @roomEditEvent="roomEditEvent"
             @roomPutawayEvent="roomPutawayEvent"
             @roomSoldOutEvent="roomSoldOutEvent" @roomSceneEvent="roomSceneEvent" @roomDeleteEvent="roomDeleteEvent"
             @switcherSceneEvent="switcherSceneEvent" @relaySceneEvent="relaySceneEvent" @ioSceneEvent="ioSceneEvent"></lists>
      <room-detail ref="roomDetail" @successCallback="$refs.lists.updateData()"></room-detail>
      <scene ref="scene" @updateData="$refs.lists.updateData()"></scene>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Lists from '@/components/Lists'
  import RoomDetail from './roomDetail'
  import Scene from './scene'
  export default {
    name: "store-info",
    components:{
      Lists,RoomDetail,Scene
    },
    data() {
      return {
        boxShow:false,
        storeNav: '',
        store_id: null,
        business_hours: null,
        lists: {
          title: [{text: '包间CODE'},{text: '包间名'},{text:'包间状态'},{text:'操作'}],
          content: [],
          data: []
        },
        listsStyle: [
          {width: '20%'},
          {width: '20%'},
          {width: '20%',justifyContent: 'center'},
          {width: '40%'}
        ],
        httpRequestData: {
          url: '/manage/store_room',
          func: this.httpRequestHandle,
          error: '获取体验店包间信息出错',
          auto: false
        }
      }
    },
    computed: {
      ...mapGetters({
        role: 'user/role'
      })
    },
    methods:{
      init(store_id,business_hours,nav) {
        if(store_id !== undefined){
          this.store_id = store_id;
          this.business_hours = business_hours;
          this.$refs.lists.updateData({store_id: store_id});
          this.storeNav = nav;
        }
        this.boxShow = true;
      },
      httpRequestHandle(data) {
        return data.map((item)=>{
          let operation =  {arr: [
              {text: '查看',style: {color: '#1890FF'},event: 'roomLookEvent'},
              {text: '编辑',style: {color: '#1890FF'},event: 'roomEditEvent'},
              {text: '上架',style: {color: '#1890FF'},event: 'roomPutawayEvent'},
              {text: '下架',style: {color: '#FF2118'},event: 'roomSoldOutEvent'},
              {text: '场景',style: {color: '#1890FF'},event: 'roomSceneEvent'},
              {text: '删除',style: {color: '#FF2118'},event: 'roomDeleteEvent'},
              {text: '二维码',style: {color: '#1890FF'},event: 'qrEvent'},
              {text: '开关状态',style: {color: '#1890FF'},event: 'switcherSceneEvent'},
              {text: '继电器状态',style: {color: '#1890FF'},event: 'relaySceneEvent'},
              {text: 'IO状态',style: {color: '#1890FF'},event: 'ioSceneEvent'},
            ]};
          return [
            {text: item.box_code},
            {text: item.room_name},
            {text: this.$config.roomStatus.getBin(item.status)},
            operation
          ];
        });
      },
      createRoomEvent() {
        this.$refs.roomDetail.init(this.store_id,this.business_hours,'create');
      },
      backEvent() {
        this.boxShow = false;
        this.$emit('callback','storeInfo');
      },
      roomLookEvent(data) {
        this.$refs.roomDetail.init(this.store_id,this.business_hours,'view',data);
      },
      roomEditEvent(data) {
        this.$refs.roomDetail.init(this.store_id,this.business_hours,'edit',data);
      },
      roomPutawayEvent(data) {
        if(data.status[2] == 1){
          this.$modal({content: '该包间已上架，不可重复上架'});
          return;
        }
        this.$api.put('/manage/roomstatus/'+data.room_id,{}).then(() => {
          this.$alert.success({content: '上架包间成功'});
          this.$refs.lists.updateData();
        }).catch(() => {
          this.$alert.error({content: '上架包间失败'});
        })
      },
      roomSoldOutEvent(data) {
        if(data.status[2] == 0){
          this.$modal({content: '该包间已下架，不可重复下架'});
          return;
        }
        this.$api.delete('/manage/roomstatus/'+data.room_id,{}).then(() => {
          this.$alert.success({content: '下架包间成功'});
          this.$refs.lists.updateData();
        }).catch(() => {
          this.$alert.error({content: '下架包间失败'});
        })
      },
      roomSceneEvent(data) {
        this.$refs.scene.init('room',data.room_id,'包间场景','/manage/scene','名称、别名、场景代码、modelIo');
      },
      switcherSceneEvent(data) {
        this.$refs.scene.init(1,data.room_id,'开关状态','/manage/boxinfo','名称、设备ID、路数');
      },
      relaySceneEvent(data) {
        this.$refs.scene.init(2,data.room_id,'继电器状态','/manage/boxinfo','名称、设备ID、路数');
      },
      ioSceneEvent(data) {
        this.$refs.scene.init(3,data.room_id,'IO状态','/manage/boxinfo','名称、设备ID、路数');
      },
      roomDeleteEvent(data) {
        this.$delete({
          title: '删除包间',
          tooltipContent: '请输入当前包间名称以删除当前代理商,数据将不可恢复，谨慎操作',
          value: data.room_name,
          confirm: () => {
            this.$api.delete('/manage/store_room/' + data.room_id).then(() => {
              this.$alert.success({content: '删除包间成功'});
              this.$refs.lists.updateData();
            }).catch((error) => {
              this.$alert.error({content: '删除包间失败:' + error});
            });
          }
        })
      }
    }
  }
</script>

<style scoped>
  .infoBox>.title{
    width: 100%;
    height: 80px;
    line-height: 80px;
    padding: 0 30px;
  }
  .infoBox>.title>button{
    margin: 20px 0 0 20px;
  }
</style>
