<template>
    <div class="liveAccount bg_white">
        <div class="header">
            <button type="create" class="fr" @click="createEvent">新建</button>
        </div>
        <lists ref="lists" :data="lists" :listsStyle="listsStyle" :httpRequestData="httpRequestData" @editEvent="editEvent" @deleteEvent="deleteEvent" @resetPassword="resetPassword"></lists>
        <detail ref="detail" @successCallback="$refs.lists.updateData()"></detail>
    </div>
</template>

<script>
    import Lists from '@/components/Lists'
    import Detail from './detail'
    export default {
        name: "liveAccount",
        components: {
            Lists, Detail
        },
        data() {
            return {
                lists: {
                    title: [{text: '账户名'}, {text: '账号'}, {text: '操作'}],
                    content: []
                },
                listsStyle: [
                    {width: '30%'},
                    {width: '30%'},
                    {width: '30%'},
                ],
                httpRequestData: {
                    url: '/manage/liveaccount',
                    func: this.httpRequestHandle,
                    error: '获取直播账户信息出错',
                    auto: true
                },
            }
        },
        methods: {
            httpRequestHandle(data) {
                let operation = {
                    arr: [
                        {text: '编辑', style: {color: '#1890FF'}, event: 'editEvent'},
                        {text: '重置密码', style: {color: '#1890FF'}, event: 'resetPassword'},
                        {text: '删除', style: {color: '#FF2118'}, event: 'deleteEvent'}
                    ]
                };
                return data.map(item =>{
                    return [
                        {text: item.name},
                        {text: item.account},
                        operation
                    ]
                });
            },
            createEvent() {
                this.$refs.detail.init('create');
            },
            editEvent(data) {
                this.$refs.detail.init('edit', data);
            },
            deleteEvent(data) {
                this.$delete({
                    title: '删除直播账户',
                    tooltipContent: '请输入当前账户名以删除当前直播账户,数据将不可恢复，谨慎操作',
                    value: data.name,
                    confirm: () => {
                        this.$api.delete('/manage/liveaccount/' + data.id).then(() => {
                            this.$alert.success({content: '删除直播账户成功'});
                            this.$refs.lists.updateData();
                        }).catch((error) => {
                            this.$alert.error({content: '删除直播账户失败:' + error});
                        });
                    }
                })
            },
            resetPassword() {
                // this.$api.delete('/manage/agent/rest_password/' + data.id).then(() => {
                //     this.$alert.success({content: '重置密码成功'});
                // }).catch((error) => {
                //     this.$alert.error({content: '重置密码失败:' + error});
                // });
            },
        }
    }
</script>

<style scoped>
    .liveAccount > .header {
        height: 80px;
        padding: 0 30px;
    }

    .liveAccount > .header > button {
        margin-top: 20px;
    }
</style>
