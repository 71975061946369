<template>
  <div class="selectionBox" @click="selectEvent" @mouseover="mouseoverEvent" @mouseout="mouseoutEvent">
    <div class="icon fr">
        <span class="iconfont icon-ios-arrow-down f14" v-show="!inputClearShow"></span>
        <span class="iconfont icon-ios-close-circle f14" v-show="inputClearShow" @click.stop="inputClearEvent"></span>
    </div>
    <div class="show" v-text="showText===''? '请选择' : showText" :class="showText===''&&'placeholder'"></div>
    <ul class="options" v-show="optionsShow">
      <li v-for="(v,key) in options" :key="key" v-text="typeof v==='object' ? v.text : v" @click.stop="optionEvent(v,key)"></li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "Selection",
    data() {
      return {
        clickFlag: false,
        optionsShow: false,
        inputClearShow: false,
        showText: '',
      }
    },
    created() {
      this.showText = this.selectedText;
      document.body.addEventListener('click',this.closeOptions,false);
    },
    destroyed() {
      document.body.removeEventListener('click',this.closeOptions);
    },
    methods: {
      selectEvent() {
        this.clickFlag = true;
        this.optionsShow = !this.optionsShow
      },
      mouseoverEvent() {
        if(this.showText !== ''){
          this.inputClearShow = true;
        }
      },
      mouseoutEvent() {
        this.inputClearShow = false;
      },
      inputClearEvent() {
        this.inputClearShow = false;
        this.showText = '';
        this.$emit('changeOption', '');
        this.$emit('change', null);
      },
      closeOptions() {
        if(this.clickFlag) {
          this.clickFlag = false;
        }else {
          this.optionsShow = false;
        }
      },
      optionEvent(data) {
        this.closeOptions();
        let text = typeof data === 'object' ? data.text : data;
        if(this.showText !== text){
          this.showText = text;
          this.$emit('changeOption', text);
          this.$emit('change', data);
        }
      }
    },
    props: {
      options: Array,
      selectedText: {
        type: String,
        default: ''
      }
    },
    model: {
      prop: 'selectedText',
      event: 'changeOption'
    },
    watch:{
      options () {
        if(this.options.length <= 0){
          this.$emit('changeOption','');
        }
      },
      selectedText (val) {
        this.showText = val;
        this.$emit('changeOption', val);
      }
    }
  }
</script>

<style scoped>
  .selectionBox{
    border:1px solid rgba(204,204,204,1);
    border-radius:3px;
    position: relative;
  }
  .selectionBox:hover{
    border-color: #57a3f3;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(45,140,240,.2);
  }
  .selectionBox>.icon{
    width: 30px;
    height: 100%;
    float: right;
    position: relative;
    cursor: pointer;
  }
  .selectionBox>.icon>i{
    position: absolute;
    top: 50%;
    margin-top: -7px;
    right: 8px;
  }
  .selectionBox>.show{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 10px;
    cursor: pointer;
    height: 100%;
  }
  .selectionBox>.placeholder{
    color: rgba(0,0,0,.3);
  }
  .selectionBox>.options{
    width: 100%;
    max-height: 210px;
    padding: 10px 0;
    background-color: white;
    position: absolute;
    top: 120%;
    box-shadow:0px 4px 12px 0px rgba(0,0,0,0.15);
    border:1px solid rgba(0,0,0,0.12);
    overflow: auto;
    z-index: 30;
  }
  .selectionBox>.options>li{
    width: 100%;
    height: 38px;
    line-height: 38px;
    font-size:14px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(0,0,0,0.87);
    background: none;
    padding-left: 10px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .selectionBox>.options>li:hover{
    color: white;
    background: #E91E63;
  }
</style>
