<template>
  <div class="store bg_white" v-show="show">
    <div class="header">
      <button type="create" class="fr" @click="createStoreEvent">新建</button>
    </div>
    <lists ref="lists" :data="lists" :listsStyle="listsStyle" :httpRequestData="httpRequestData" @storeEditEvent="storeEditEvent" @storeLookEvent="storeLookEvent" @storeRoomEvent="storeRoomEvent"
            @storePutawayEvent="storePutawayEvent" @storeSoldOutEvent="storeSoldOutEvent" @storeDeleteEvent="storeDeleteEvent"></lists>
    <store-detail ref="storeDetail" @successCallback="$refs.lists.updateData()"></store-detail>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Lists from '@/components/Lists'
  import StoreDetail from './storeDetail'
  export default {
    name: "storeInfo",
    components:{
      Lists,StoreDetail
    },
    data() {
      return {
        show: false,
        lists: {
          title: [{text: '门店名称'},{text:'地址'},{text:'服务电话'},{text:'体验店状态'},{text:'包间数量'},{text:'操作'}],
          content: [],
          data: []
        },
        listsStyle: [
          {width: '20%'},
          {width: '30%'},
          {width: '15%'},
          {width: '10%',justifyContent: 'center'},
          {width: '10%',justifyContent: 'center'},
          {width: '15%'},
        ],
        httpRequestData: {
          url: '/manage/store',
          func: this.httpRequestHandle,
          error: '获取体验店信息出错',
          auto: false
        }
      }
    },
    computed: {
      ...mapGetters({
        role: 'user/role',
      })
    },
    methods:{
      init() {
        this.show = true;
        this.$refs.lists.updateData();
      },
      httpRequestHandle(data) {
        return data.map(item=>{
          let operation = {arr :[
              {text: '查看',style: {color: '#1890FF'},event: 'storeLookEvent'},
              {text: '编辑',style: {color: '#1890FF'},event: 'storeEditEvent'},
              {text: '上架',style: {color: '#1890FF'},event: 'storePutawayEvent'},
              {text: '下架',style: {color: '#FF2118'},event: 'storeSoldOutEvent'},
              {text: '包间',style: {color: '#1890FF'},event: 'storeRoomEvent'},
              {text: '删除',style: {color: '#FF2118'},event: 'storeDeleteEvent'}
              ]};
          return [
            {text: item.store_name},
            {text: item.province+item.city+item.district+item.store_address},
            {text: item.service_phone},
            {text: this.$config.storeType.get(item.status)},
            {text: item.room_num},
            operation
          ];
        });
      },
      createStoreEvent() {
        this.$refs.storeDetail.init('create');
      },
      storeEditEvent(data) {
        this.$refs.storeDetail.init('edit',data);
      },
      storeLookEvent(data) {
        this.$refs.storeDetail.init('view',data);
      },
      storeRoomEvent(data) {
        this.show = false;
        let nav = data.province + ' · ' + data.city + ' · ' + data.district + ' · ' + data.store_name;
        this.$emit('callback','roomInfo',data.store_id,data.business_hours,nav);
      },
      storePutawayEvent(data) {
        if(data.status){
          this.$modal({content: '该体验店上架，不可重复上架'});
          return;
        }
        this.$api.put('/manage/shopstatus/'+data.store_id,{}).then(() => {
          this.$alert.success({content: '上架体验店成功'});
          this.$refs.lists.updateData(this.filter);
        }).catch((error) => {
          this.$alert.error({content: '上架体验店失败:' + error});
        })
      },
      storeSoldOutEvent(data) {
        if(!data.status){
          this.$modal({content: '该体验店已下架，不可重复下架'});
          return;
        }
        this.$api.delete('/manage/shopstatus/'+data.store_id,{}).then(() => {
          this.$alert.success({content: '下架体验店成功'});
          this.$refs.lists.updateData(this.filter);
        }).catch((error) => {
          this.$alert.error({content: '下架体验店失败:' + error});
        })
      },
      storeDeleteEvent(data) {
        this.$delete({
          title: '删除体验店',
          tooltipContent: '请输入当前体验店名称以删除当前体验店,数据将不可恢复，谨慎操作',
          value: data.store_name,
          confirm: () => {
            this.$api.delete('/manage/store/' + data.store_id).then(() => {
              this.$alert.success({content: '删除体验店成功'});
              this.$refs.lists.updateData();
            }).catch((error) => {
              this.$alert.error({content: '删除体验店失败:' + error});
            });
          }
        });
      }
    }
  }
</script>

<style scoped>
  .store {
    padding: 0 20px;
  }
  .header{
    width: 100%;
    padding: 0 30px;
    height: 80px;
  }
  .header .cascader{
    width: 400px;
  }
  .header > button {
    margin-top: 20px;
  }
</style>
