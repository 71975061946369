<template>
    <div class="live bg_white" v-show="show">
        <div class="cards">
            <div v-for="(v,key) in lives" :key="key" @click="liveTapEvent(v)">
                <div class="fl">
                    <span class="iconfont icon-airudiantubiaohuizhi-zhuanqu_zhibojian f40 txt_white"></span>
                </div>
                <div class="fl">
                    <div class="title txt_white f16" v-text="v.name"></div>
                    <div class="description txt_white f12" v-text="v.description"></div>
                </div>
                <span title="删除直播间" class="del iconfont icon-shanchu txt_white" @click.stop="deleteLive(v)"></span>
                <span title="编辑直播间" class="edit iconfont icon-bianji txt_white" @click.stop="editLive(v)"></span>
                <span class="scene f12 txt_white" @click.stop="sceneConfig(v)">配置场景 <span class="iconfont icon-youjiantou"></span> </span>
            </div>
            <div @click="createLive">
                <div class="fl">
                    <span class="iconfont icon-airudiantubiaohuizhi-zhuanqu_zhibojian f40 txt_white"></span>
                </div>
                <div class="fl create">
                    <div class="txt_white f36 iconfont icon-xinjian"></div>
                    <div class="txt_white f12">新建直播间</div>
                </div>
            </div>
            <div class="temp" v-for="(v, key) in 5" :key="key + lives.length"></div>
        </div>
        <detail ref="detail" @successCallback="getLiveRoom"></detail>
        <scene ref="scene" @successCallback="getLiveRoom"></scene>
    </div>
</template>

<script>
    import Detail from './detail'
    import Scene from './scene'
    export default {
        name: "live",
        components:{
          Detail,Scene
        },
        data() {
          return {
              show: true,
              lives: []
          }
        },
        created() {
            this.getLiveRoom();
        },
        methods: {
            init() {
              this.show = true;
            },
            getLiveRoom() {
                this.$func.getAllData('/manage/liveroom', data =>{
                    this.lives = data;
                }, null,  '获取直播间失败');
            },
            liveTapEvent(data) {
                this.show = false;
                this.$emit('callback','sendScene', data);
            },
            createLive() {
                this.$refs.detail.init('create');
            },
            editLive(data) {
                this.$refs.detail.init('edit', data);
            },
            deleteLive(data) {
                this.$delete({
                    title: '删除直播间',
                    tooltipContent: '请输入当前直播间名称以删除直播间,数据将不可恢复，谨慎操作',
                    value: data.name,
                    confirm: () => {
                        this.$api.delete('/manage/liveroom/' + data.id).then(() => {
                            this.$alert.success({content: '删除直播间成功'});
                            this.getLiveRoom();
                        }).catch((error) => {
                            this.$alert.error({content: '删除直播间失败:' + error});
                        });
                    }
                })
            },
            sceneConfig(data) {
                this.$refs.scene.init(data);
            }
        }
    }
</script>

<style scoped>
    .cards {
        width: 100%;
        padding: 40px 20px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .cards > div {
        width: 320px;
        height: 150px;
        background: #207afb;
        margin-bottom: 20px;
        border-radius: 7px;
        position: relative;
        cursor: pointer;
    }
    .cards > .temp {
        height: 0;
        margin-bottom: 0;
        border: none;
        padding: 0;
    }
    .cards > div:hover {
        box-shadow: 2px 2px 2px 2px #888888;
    }
    .cards > div > .del {
        position: absolute;
        top: 5px;
        right: 25px;
        cursor: pointer;

    }
    .cards > div > .edit {
        position: absolute;
        top: 3px;
        right: 5px;
        cursor: pointer;
        font-size: 20px;
    }
    .cards > div > .scene {
        position: absolute;
        bottom: 5px;
        right: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .cards > div > .del:active, .cards > div > .scene:active{
        opacity: .6;
    }
    .cards > div > div:nth-of-type(1) {
        width: 60px;
        height: 140px;
        line-height: 140px;
        text-align: center;
    }
    .cards > div > div:nth-of-type(2) {
        width: 210px;
        height: 65px;
        margin-top: 20px;
    }
    .cards > div > div > .description {
        width: 100%;
        margin-top: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .cards > div > .create {
        height: 80px !important;
        width: 100% !important;
        margin-left: -60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>